import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
//sacamos los thunks de l archivos y los trabajamos en un archivo aparte
import { getSubscribedUsersThunk, getAllActivitiesThunk, createActivityThunk, deleteActivityThunk, getActivityThunk } from './thunks'
import {createMultipleCerts, attendanceBulk, createCertThunk, freeStatusChangeThunk, paidStatusChangeThunk, modifyAttendanceThunk} from '../activityRegistration/thunks'
import {errorHandler} from '../utils/errorHandler'
const initialState = {
  all: [],
  allLoading: true,
  allError: null,
  allMessage: null,
  ////
  activity: {},
  activityLoading: false,
  activityError: null,
  activityMessage: null,
  ////
  subscribedUsers: [],
  subscribedUsersLoading: true,
  subscribedUsersError: null,
  subscribedUsersMessage: null
};

export const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    swipeActivityData: (state) => {
      state.activityError = null;
      state.activityMessage = null;
    }
  },
  extraReducers: builder => {
    
    builder
      .addCase(getSubscribedUsersThunk.rejected, (state, action) => {
        state.subscribedUsersLoading = false;
        state.subscribedUsersError = {
          message:
            "Ha ocurrido un error, por favor intente nuevamente en unos momentos",
        };
        state.subscribedUsersMessage = null;
      })

      .addCase(createCertThunk.fulfilled, (state, action) => {
        state.subscribedUsersLoading = false;
        state.subscribedUsersError = false;
        state.subscribedUsersMessage = action.payload.message;
      })

      .addCase(createCertThunk.rejected, (state, action) => {
        state.subscribedUsersLoading = false;
        state.subscribedUsersError = true;
        state.subscribedUsersError = action.payload.message;
      })

      .addMatcher(isPending(getSubscribedUsersThunk, attendanceBulk, createCertThunk, createMultipleCerts), (state, action) => {
        state.subscribedUsersLoading = true;
        state.subscribedUsersError = false;
        state.subscribedUsersMessage = action.payload?.message;
      })

      .addMatcher(isFulfilled(getSubscribedUsersThunk, attendanceBulk), (state, action) => {
        state.subscribedUsersLoading = false;
        state.subscribedUsersError = false;
        state.subscribedUsersMessage = action.payload?.message;
        state.subscribedUsers = action.payload?.subscribedUsers;
      })

      .addMatcher(isFulfilled(createMultipleCerts), (state, action) => {
        state.subscribedUsersLoading = false;
        state.subscribedUsersError = false;
        state.subscribedUsersMessage = action.payload?.message;
      })

      

      .addMatcher(isPending(getSubscribedUsersThunk, attendanceBulk, createMultipleCerts), (state) => {
        state.allLoading = true;
        state.allError = null;
        state.allMessage = null;
      })

      .addMatcher(isPending(getAllActivitiesThunk), (state) => {
        state.allLoading = true;
        state.allError = null;
        state.allMessage = null;
      })

      .addMatcher(
        isPending(createActivityThunk, deleteActivityThunk, getActivityThunk),
        (state) => {
          state.activityLoading = true;
          state.activityError = false;
          state.activityMessage = null;
        }
      )

      .addMatcher(isFulfilled(getAllActivitiesThunk), (state, action) => {
        state.allLoading = false;
        state.allError = false;
        state.allMessage = action.payload.message;
        state.all = action.payload.activities;
      })

      .addMatcher(
        isFulfilled(createActivityThunk, deleteActivityThunk, getActivityThunk),
        (state, action) => {
          state.activityLoading = false;
          state.activityError = null;
          //Este mensaje debe venir del servidor
          state.activityMessage = action.payload.message;
          state.activity = action.payload.activity;
        }
      )

      .addMatcher(
        isFulfilled(paidStatusChangeThunk, freeStatusChangeThunk),
        (state, action) => {
          state.subscribedUsers = state.subscribedUsers.map((activityReg) => {
            if (activityReg._id === action.payload.activityReg._id) {
              activityReg = action.payload.activityReg;
            }
            return activityReg;
          });
        }
      )

      .addMatcher( 
        isFulfilled(freeStatusChangeThunk, modifyAttendanceThunk),
        (state, action) => {
          state.subscribedUsers = state.subscribedUsers.map(activity => {
            if (activity._id === action.payload.activityReg._id) {
              activity.attended = action.payload.activityReg.attended;
              activity.status = action.payload.activityReg.status;
            }
            return activity;
          })
          state.subscribedUsersMessage = action.payload.message;
        }
      )

      .addMatcher(
        isRejectedWithValue(getAllActivitiesThunk),
        (state, action) => {
          errorHandler(action.payload);
          state.allLoading = false;
          state.allError = {
            message:
              "Ha ocurrido un error, por favor intente nuevamente en unos momentos",
          };
        }
      )

      .addMatcher(
        isRejectedWithValue(
          createActivityThunk,
          deleteActivityThunk,
          getActivityThunk,
          createMultipleCerts,
          attendanceBulk
        ),
        (state, action) => {
          errorHandler(action.payload);
          state.activityLoading = false;
          state.activityError = {
            message:
              "Ha ocurrido un error, por favor intente nuevamente en unos momentos",
          };
          state.activityData = {};
        }
      );
  }
});

export const { swipeActivityData } = activitiesSlice.actions;



export default activitiesSlice.reducer;
