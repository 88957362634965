import React, { useEffect, useState } from 'react';
import AdminTable from '../../components/tables/Table';
import { Container, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getAllCodesThunk } from '../../redux/codes/thunks';

export default function CodesList() {
    const dispatch = useDispatch();
    const bodyData = useSelector((state) => state.codes.all);
    const loading = useSelector((state) => state.codes.allLoading);
    const error = useSelector(state => state.codes.allError?.message);
    //let requestSend = false;
    const [refresh, setRefresh] = useState(false)
    const [alert, setAlert] = useState(true);

    const tableHeader = [
        { label: "Actividad", name: "activity" },
        { label: "Usados", name: "used" },
        { label: "Disponibles", name: "available" },
        { label: "Totales", name: "total" }
    ];

    const buttons = [
        {
          name: "details",
          overlay: "Ver clientes",
          icon: "eye",
          //conditional:{ key:"registeredUsers", opossiteValues: [0] },
          path: "codes/clients",
        },
      ];

    useEffect(() => {
        dispatch(getAllCodesThunk());
    }, [refresh])

    return (
        <>
            {error ? (
                <p>Ha ocurrido un error. Por favor vuelva en intentar en unos minutos</p>
            ) : loading ? (
                <p>Loading</p>
            ) : (
                <Container fluid>
                    <h2 className="text-center">Todas las actividades con códigos</h2>
                    <Container fluid style={{ marginTop: "30px" }} >
                        <AdminTable
                            bodyData={bodyData}
                            buttons={buttons}
                            tableHeader={tableHeader}
                            buttonsWidth={'162px'}
                            size={20}
                        />
                    </Container>
                </Container>
            )}
        </>
    )
}
