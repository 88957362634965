import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth/authSlice';
import activitiesReducer from './activities/activitiesSlice';
import usersReducer from './users/usersSlice';
import homeViewReducer from './homeView/homeViewSlice';
import activityRegistration from './activityRegistration/activityRegistrationSlice';
import subscriptionsReducer from './subscriptions/subscriptionsSlice';
import codesReducer from './codes/codesSlice';
import popUpsReducer from './pop_ups/pop_upsSlice';

export const store = configureStore({
  reducer: {
    activities: activitiesReducer,
    auth: authReducer,
    users: usersReducer,
    homeView: homeViewReducer,
    activityRegistration: activityRegistration,
    subscriptions: subscriptionsReducer,
    codes: codesReducer,
    pop_ups: popUpsReducer
  },
});
