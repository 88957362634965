import axios from "axios";
import { authHeader } from "./authHeader";
import { api_url } from '../config'

export const freeStatusChange = async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader(),
      },
    };
    const response = await axios.get(
      `${api_url}/activityRegistrations/freeStatusChange/${id}`,
      options
    );
    return response.data
  } catch (e) {
    return e;
  }
};

export const modifyAttendance = async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader(),
      },
    };
    const response = await axios.get(
      `${api_url}/activityRegistrations/modifyAttendance/${id}`,
      options
    );

    return response.data;
  } catch (e) {
    return e;
  }
};

export const paidStatusChange = async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader(),
      },
    };
    const response = await axios.get(
      `${api_url}/activityRegistrations/paidStatusChange/${id}`,
      options
    );

    return response.data;
  } catch (e) {
    return e;
  }
};


export const createCert = async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader(),
      },
    };
    const response = await axios.get(
      `${api_url}/activityRegistrations/createCertificate/${id}`,
      options
    );

    return response.data;
  } catch (e) {
    return e;
  }
};



export const revokeCertificate = async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader(),
      },
    };
    const response = await axios.get(
      `${api_url}/activityRegistrations/revokeCertificate/${id}`,
      options
    );

    return response.data;
  } catch (e) {
    return e;
  }
};

export const getAllCerts = async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader(),
      },
    };
    const response = await axios.get(
      `${api_url}/activityRegistrations/getAllCerts`,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

export const downloadCertificate = async (id) => {
  try {
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...authHeader(),
      },
      responseType: 'arraybuffer'
    };
    const response = await axios.get(
      `${api_url}/activityRegistrations/downloadCertificate/${id}`,
      options
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const resendEmail = async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader(),
      },
    };
    const response = await axios.get(
      `${api_url}/activityRegistrations/resendCertificate/${id}`,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};


export const attendanceBulkService = async (data) => {
  try {
   
    let options = {
      headers: {
        headers: { "content-type": "application/json" },
        ...authHeader()
      }
    };
    const response = await axios.post(
      `${api_url}/activityRegistrations/attendanceBulk`,
      data,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

export const createMultipleCertsService = async (data) => {
  try {
    let options = {
      headers: {
        headers: { "content-type": "application/json" },
        ...authHeader()
      }
    };
    const response = await axios.post(
      `${api_url}/activityRegistrations/createMultipleCertificates`,
      data,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};











