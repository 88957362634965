import axios from "axios";
import { authHeader } from './authHeader'
import { api_url } from '../config'
//SIEMPRE TOMAR LA API_URL DE LOS AMBIENTES


export const getAllCodes = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${api_url}/codes/getAll`,
      options
    );
    
    return response.data;
  } catch (e) {
    return e;
  }
};

export const getACode = async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${api_url}/codes/getOne/${id}`,
      options
    );
    
    return response.data;
  } catch (e) {
    return e;
  }
};

export const getClientsByActivity = async (id) => {
    try {
      let options = {
        headers: {
          "content-type": "application/json",
          ...authHeader()
        },
      };
      const response = await axios.get(
        `${api_url}/codes/clients/${id}`,
        options
      );
      
      return response.data;
    } catch (e) {
      return e;
    }
  };


export const createCode = async (data) => {
  try {
    let options = {
      headers: {
        ...authHeader()
      }
    };
    const response = await axios.post(
      `${api_url}/codes/create`,
      data,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};


export const getCodesByClientsAndActivity = async (activityId, id) => {
    try {
      let options = {
        headers: {
          "content-type": "application/json",
          ...authHeader()
        },
      };
      const response = await axios.get(
        `${api_url}/codes/clientsAndActivity/${activityId}/${id}`,
        options
      );
      
      return response.data;
    } catch (e) {
      return e;
    }
  };

