import React from 'react';
import {useSelector} from 'react-redux';
import GetBilling from '../../components/billing/GetBilling';


export default function UserActivities({setBackdropShow}) {
  const bodyData = useSelector((state) => state.users.userBilling);
  const loading = useSelector((state) => state.users.userLoading);
  const error = useSelector((state) => state.users.userError);

  return (
    <>
        <GetBilling bodyData={bodyData} setBackdropShow={setBackdropShow} loading={loading} error={error} size={5} />
    </>
  );
}
