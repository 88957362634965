export default function dataToRender(bodyData) { 
    let formData = [
      {
        label: null,
        value: bodyData?.visible ? true : false,
        type: "switch",
        size: 12,
        name: "visible",
        inputOptions: [{value: true, label: "Activo"}]
      },
        {
          label: "Imagen escritorio",
          value: bodyData?.imgPath,
          type: "file",
          size: 6,
          name: "imgPath",
          required: false
        },
        {
          label: "Imagen movil",
          value: bodyData?.imgPathMobile,
          type: "file",
          size: 6,
          name: "imgPathMobile",
          required: false
        },
      ];

      return formData

}