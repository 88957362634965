import React from "react";
import { Routes, Route } from "react-router-dom";
import  Layout1  from "./views/layout/layout-1";
import Login from "./views/auth/Login";
import Reset from "./views/auth/Reset";
import Forgot from "./views/auth/Forgot";
import LoggedIn from "./components/validate/LoggedIn";
import NotLoggedIn from "./components/validate/NotLoggedIn";
import EditUser from './views/users/EditUser'
import UserDetails from './views/users/UserDetails'
import GetAllActitivies from './views/activities/GetAllActivities';
import EditActivity from './views/activities/EditActivity';
import CreateActivity from './views/activities/CreateActivity';
import SubscribedUsers from './views/activities/SubscribedUsers';
import GetAllUsers from './views/users/GetAllUsers';
import GetBanner from './views/homeView/GetBanner';
import Home from './views/homeView/Home';
import GetAllCertificates from "./views/certificates/GetAllCertificates";
import UpdateSubscription from './views/subscriptions/UpdateSubscription';
import "./css/App.scss";
import CreateCode from "./views/codes/CreateCode";
import GetAllCodes from "./views/codes/GetAllCodes";
import ClientsByActivity from "./views/codes/ClientsByActivity";
import CodesByClientAndActivity from './views/codes/CodesByClientAndActivity'
import GetPopUp from "./views/pop_ups/Get_pop_up";

function App() {
  return (
    <>
      <Routes>
        <Route element={<LoggedIn />}>
            <Route path="/" element={<Layout1 page={<Home />}/>} />
            <Route path="/users/getAll" element={<Layout1 page={<GetAllUsers />}/>} />
            <Route path="/homeView/getBanner" element={<Layout1 page={<GetBanner />}/>} />
            <Route path="/activities/createActivity" element={<Layout1 page={<CreateActivity />}/>} />
            <Route path="/activities/getAll" element={<Layout1 page={<GetAllActitivies />}/>} />
            <Route path="/users/edit/:id" element={<Layout1 page={<EditUser />}/>} />
            <Route path="/users/details/:id" element={<Layout1 page={<UserDetails />}/>} />
            <Route path="/activities/edit/:activityId" element={<Layout1 page={<EditActivity />}/>} />
            <Route path="/activities/subscribedUsers/:activityId" element={<Layout1 page={<SubscribedUsers />}/>} />
            <Route path="/certificates/all" element={<Layout1 page={<GetAllCertificates />}/>} />
            <Route path="/subscriptions/update" element={<Layout1 page={<UpdateSubscription />}/>} />
            <Route path="/codes/create" element={<Layout1 page={<CreateCode />}/>} />
            <Route path="/codes/activities/all" element={<Layout1 page={<GetAllCodes />}/>} />
            <Route path="/codes/clients/:activityId" element={<Layout1 page={<ClientsByActivity />}/>} />
            <Route path="/codes/byClientAndActivity/:activityId/:client" element={<Layout1 page={<CodesByClientAndActivity />}/>} />
            <Route path="/pop_up" element={<Layout1 page={<GetPopUp />}/>} />
        </Route>

        <Route element={<NotLoggedIn />}>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/register" element={<Register />} /> */}
        </Route>

        <Route path="/forgot" element={<Forgot />} />
        <Route path="/reset" element={<Reset />} />
      </Routes>
    </>
  );
}

export default App;
