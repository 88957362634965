import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getUserThunk, updateUserThunk} from '../../redux/users/thunks';
import { useParams } from "react-router-dom";
import { Container, Alert} from "react-bootstrap";
import CreateForm from "../../components/forms/CreateForm";
import renderInstructions from './userData';


export default function EditUser() {

    const { id } = useParams();
    const dispatch = useDispatch();
    const bodyData = useSelector(state => state.users.user);
    const loading = useSelector(state => state.users.userLoading);
    const error = useSelector(state => state.users.userError?.message);
    const message = useSelector(state => state.users.userMessage);
    let requestSend = false;
    const dataToRender = renderInstructions(bodyData);
   
    useEffect(()=>{
        if(!requestSend) {
            dispatch(getUserThunk(id));
            // eslint-disable-next-line react-hooks/exhaustive-deps
            requestSend=true;
        }
    },[])


    const formAction = (dataToSubmite) => {
        dispatch(updateUserThunk({...dataToSubmite, id}));
      } 
     
    return (
      <>
        {loading ? (
          <p>Loading</p>
        ) : (
          <Container>
            <h2 className="text-center">
              Editar a {bodyData.name} {bodyData.lastName}
            </h2>
            <Container style={{ marginTop: "30px" }}>
              <CreateForm formData={dataToRender} action={formAction} message={message} error={error} />
            </Container>
          </Container>
        )}
      </>
    );
}
