import { FloatingLabel, Form, Col } from "react-bootstrap";

export default function SelectInput({data, handleChange}) {

   let {label, value, size, name, inputOptions} = data;

   inputOptions.sort(function (a) {
    if (a.value === value) return -1;
    else if (!value) return 0;
    return 1;
    })

    return (
        <Col xs={size}>
          <FloatingLabel
                controlId={`${name}-floatingInput`}
                label= {label}
                className="mb-3"
              >
                <Form.Select aria-label="Floating label select example" name= {name} {...data.required && {required: true}} onChange={handleChange} >
                  {!value && <option ></option> }
                  {inputOptions.map((option, index) => { 
                    if(value === option.value){
                      return <option defaultValue={option.value} key={index} value={option.value}>{option.label}</option>
                    }  else{
                      return <option key={index} value={option.value}>{option.label}</option>
                    }
                    })}
                </Form.Select>
                <Form.Control.Feedback />
              </FloatingLabel>
        </Col>
    )
  }
  