import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionThunk, updateSubscriptionThunk } from "../../redux/subscriptions/thunks";
import CreateForm from "../../components/forms/CreateForm";
import renderInstructions from './SubscriptionsData';

export default function UpdateSubscription() {
 // let { activityId } = useParams();
  const dispatch = useDispatch();
  const bodyData = useSelector((state) => state.subscriptions.subscription);
  const loading = useSelector((state) => state.subscriptions.loading);
  const error = useSelector(state => state.subscriptions.error);
  const message = useSelector(state => state.subscriptions.message);
  const [refresh, setRefresh] = useState(false)
  const dataToRender = renderInstructions(bodyData);
  


  const formAction = (dataToSubmite) => {
    dispatch(updateSubscriptionThunk(dataToSubmite));
    setRefresh(!setRefresh)
  } 

  useEffect(() => {
        dispatch(getSubscriptionThunk());
  }, [refresh]);


  return (
    <>
      {loading ? (
        <p>Loading</p>
      ) : (
        <Container >
          <h2 className="text-center">Editar subscripción </h2>
          <Container style={{ marginTop: "30px" }}>
            <CreateForm formData={dataToRender} action={formAction} message={message} error={error}  />
          </Container>
        </Container>
      )}
    </>
  );
}
