import { createAsyncThunk} from '@reduxjs/toolkit';
import { getAllCodes, getACode, createCode, getClientsByActivity, getCodesByClientsAndActivity } from '../../services/codesServices'

export const getAllCodesThunk = createAsyncThunk(
    'codes/getAllCodesThunk',
    async (data, {rejectWithValue}) => {
      try {
        const req = await getAllCodes();
        if (!req.success) { 
          return rejectWithValue(req)
        }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );


  export const createCodeThunk = createAsyncThunk(
    'codes/createCodeThunk',
    async (data, {rejectWithValue}) => {
      try {
        let req
        if(data.activityId){
          //req = await updateActivity(data.data, data.activityId);
        }else{
          req = await createCode(data.data);
        }
        if (!req.success) { 
          return rejectWithValue(req)
        }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const getCodeThunk = createAsyncThunk(
    'codes/getCodeThunk',
    async (id, {rejectWithValue}) => {
      try {
        const req = await getCodeThunk(id);
        if (!req.success) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const getClientsByActivityThunk = createAsyncThunk(
    'codes/getClientsByActivity',
    async (id, {rejectWithValue}) => {
      try {
        const req = await getClientsByActivity(id);
        if (!req.success) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const getCodesByClientsAndActivityThunk = createAsyncThunk(
    'codes/getCodesByClientsAndActivity',
    async (data, {rejectWithValue}) => {
      try {
        const req = await getCodesByClientsAndActivity(data.activityId, data.id);
        if (!req.success) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );


  

  