import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SideBar from "../../components/navbars/Sidebar";
import Navbar from "../../components/navbars/NavigationBar";

export default function Layout(props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Navbar />
      <Container fluid>
        <SideBar isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className={`content ${isOpen ? '' : 'collapsed'}`}>{props.page}</div>
      </Container>
    </>
  );
}