import { FloatingLabel, Form, Col } from "react-bootstrap";
import { useState } from "react";

export default function RadioInput({data, handleChange}) {
  const [checked, setChecked] = useState(data.value);

 

  function handleThis(e) {
    setChecked(e.target.checked);
    e.target.value = e.target.checked;
    handleChange(e);
  }
 

   let {label, value, size, name, inputOptions, type} = data;

    return (
        <Col xs={size} className={type === 'switch' && 'text-center'}>
    
    <div key={`${name}-${type}`} className="mb-3">
    <p>{label}</p>
    {inputOptions.map((option, index) => {
      return(
         <Form.Check
         {...data.value === option.value}
         checked = {checked}
         inline
         key={index}
         label={option.label}
         value={type === 'switch' ? true : option.value}
         name={name}
         type={type}
         onChange={handleThis}
       />
       )
    })}
   
      
    </div>
  
        </Col>
    )
  }
  