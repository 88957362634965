import axios from "axios";
import { authHeader } from './authHeader'
//SIEMPRE TOMAR LA API_URL DE LOS AMBIENTES
import { api_url, api_do_url } from '../config'



export const getAllUsers = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${api_url}/user/getAll`,
      options
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getUser = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${api_url}/user/getOne/${data}`,
      options
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const updateUser = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.put(
      `${api_url}/user/updateOne/${data.id}`,
      data,
      options
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const updateUserStatus = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${api_url}/user/status/${data.status}/${data.action}/${data.id}`,
      options
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const userDetails = async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${api_url}/user/details/${id}`,
      options
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};


export const downloadCv = async (cv) => {
  try {
    let options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'arraybuffer'
    };
    const response = await axios.get(
      `${api_do_url}/${cv}`,
      options
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const re_subscribe = async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    let response = await axios.get(
      `${api_url}/user/re_subscribe/${id}`,
      options
    );

    return response.data;
  } catch (e) {
    throw e;
  }
};