import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {swipeActivityData} from "../../redux/activities/activitiesSlice";
import { createActivityThunk } from "../../redux/activities/thunks";
import CreateForm from "../../components/forms/CreateForm";
import renderInstructions from './activityData';

export default function EditActivity() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.activities.activityLoading);
  const error = useSelector(state => state.activities.activityError?.message);
  const message = useSelector(state => state.activities.activityMessage);
  const dataToRender = renderInstructions();
  

  const formAction = (dataToSubmite) => {
    dispatch(createActivityThunk({data: dataToSubmite}));
  } 

  useEffect(() => {
    dispatch(swipeActivityData());
  }, []);

  return (
    <>
      {loading ? (
        <p>Loading</p>
      ) : (
        <Container >
          <h2 className="text-center">Crear actividad</h2>
          <Container style={{ marginTop: "30px" }}>
            <CreateForm formData={dataToRender} action={formAction} message={message} error={error}  />
          </Container>
        </Container>
      )}
    </>
  );
}
