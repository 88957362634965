import axios from "axios";
import { authHeader } from './authHeader'
import { api_url } from '../config'
//SIEMPRE TOMAR LA API_URL DE LOS AMBIENTES


export const getAllActivities = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${api_url}/activities/getAll`,
      options
    );
    
    return response.data;
  } catch (e) {
    return e;
  }
};

export const getActivity = async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${api_url}/activities/getOne/${id}`,
      options
    );
    
    return response.data;
  } catch (e) {
    return e;
  }
};


export const createActivity = async (data) => {
  try {
    let options = {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        ...authHeader()
      }
    };
    const response = await axios.post(
      `${api_url}/activities/create`,
      data,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

export const updateActivity = async (data, id) => {
  try {
    let options = {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        ...authHeader()
      },
    };
    const response = await axios.put(
      `${api_url}/activities/update/${id}`,
      data,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};


export const deleteActivity = async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.delete(
      `${api_url}/activities/${id}`,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

export const getSubscribedUsers= async (id) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${api_url}/activities/getSubscribedUsers/${id}`,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};


