import React, { useEffect, useState } from 'react';
import { Container } from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux';
import {getSubscribedUsersThunk} from '../../redux/activities/thunks';
import { useParams } from "react-router-dom";
import GetActivitiesReg from '../../components/activities/GetActivitiesRegistration'


export default function SubscribedUsers() {
  const { activityId } = useParams();
  const dispatch = useDispatch();
  const bodyData = useSelector((state) => state.activities.subscribedUsers);
  const loading = useSelector((state) => state.activities.subscribedUsersLoading);
  const errorMessage = useSelector((state) => state.activities.subscribedUsersError);
  const message = useSelector((state) => state.activities.subscribedUsersMessage);
  const [refresh, setRefresh] = useState(false)

  
  useEffect(() => {
      dispatch(getSubscribedUsersThunk(activityId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const tableHeader = [
    {label: "DNI", name: 'user.dni'},
    {label:"Nombre", name: 'user.name'},
    {label:"Apellido", name: 'user.lastName'},
    { label: "Email", name: "user.email" },
    { label: "Tel", name: "user.phone" },
    { label: "Código", name: "code" },
    { label: "Presente", name: "attended", positive: 'Presente', negative:'Ausente' },
    { label: "Tipo", name: "activity.type", replacement: [{ presencial: "Presencial" }, { virtual: "Virtual" }, { mixta: "Mixto" }] },
    { label: "Estado", name: "status" }
  ];

  const headersXlsx = {
    'user.dni': "DNI",
    'user.name': "Nombre",
    'user.lastName': "Apellido",
    "user.email": "Email",
    "user.phone": "Tel",
    code: "Código",
    attended: "Presente",
    status: "Estado"
  }

  const headersXlsxAll = {
    code: "Código",
    'user.dni': "DNI",
    'user.name': "Nombre",
    'user.lastName': "Apellido",
    "user.email": "Email",
    "user.phone": "Tel",
    'user.country': "Pais",
    "user.province": "Provincia",
    "user.postalCode": "Codigo Postal",
    participant_type: "Tipo de participante",
    dietary_restrictions: "Restricciones alimenticias",
    dietary_comments: "Comentarios de restricciones alimenticias",
    special_requests: "Requerimientos especiales",
    scholarship: "Beca",
    'activity.title': "Nombre Actividad",
    'activity.startDate': "Fecha de inicio",
    'activity.finishDate': "Fecha de fin",
    attended: "Presente",
    "activity.paid": "Pago",
    status: "Estado",
    type: "Modalidad",
    price: "Precio",
    'activity.country': "Pais",
    'activity.province': "Provincia",
    'activity.postalCode': "Codigo Postal",
    'activity.address': "Direccion",
    'activity.description': "Descricpion",
    'activity.locationLink': "Link Ubicacion",
    'activity.status': "Estado de la actividad",
    'activity.doctors': "Doctores"
  }

  return (
    <>
      <Container>
        {!loading && (
          <h2 className="text-center">
            Usuarios registrados a {bodyData[0].activity.title}
          </h2>
        )}
        <Container style={{ marginTop: "30px" }}>
          <GetActivitiesReg
            bodyData={bodyData}
            loading={loading}
            errorMessage={errorMessage}
            message={message}
            tableHeader={tableHeader}
            size={20}
            headersXlsx={headersXlsx}
            headersXlsxAll={headersXlsxAll}
          />
        </Container>
      </Container>
    </>
  );
}
