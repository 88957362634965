import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { swipeCodeData } from "../../redux/codes/codesSlice";
import { createCodeThunk } from "../../redux/codes/thunks";
import { getAllActivitiesThunk } from "../../redux/activities/thunks";
import CreateForm from "../../components/forms/CreateForm";
import renderInstructions from './codeData';

export default function CreateCode() {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.codes.codeLoading);
    const error = useSelector(state => state.codes.codeError?.message);
    const message = useSelector(state => state.codes.codeMessage);
    const activities = useSelector((state) => state.activities.all);
    const [activitiesOptions, setActivitiesOptions] = useState([])
    const dataToRender = renderInstructions(null, activitiesOptions);

    useEffect(() => {
        dispatch(getAllActivitiesThunk());
    }, [])

    useEffect(() => {
        if (activities) {
            let activitiesEdit = activities.map(activity => {
                return {
                    value: activity._id,
                    label: activity.title
                }
            })
            setActivitiesOptions(activitiesEdit)
        }
    }, [activities])

    const formAction = (dataToSubmite) => {
        dispatch(createCodeThunk({ data: dataToSubmite }));
    }

    useEffect(() => {
        dispatch(swipeCodeData());
    }, []);

    return (
        <>
            {loading ? (
                <p>Loading</p>
            ) : (
                <Container >
                    <h2 className="text-center">Crear Códigos</h2>
                    <Container style={{ marginTop: "30px" }}>
                        <CreateForm formData={dataToRender} action={formAction} message={message} error={error} />
                    </Container>
                </Container>
            )}
        </>
    );
}
