import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createMultipleCertsService,
  attendanceBulkService,
  freeStatusChange,
  modifyAttendance,
  paidStatusChange,
  createCert,
  getAllCerts,
  revokeCertificate,
} from "../../services/activityRegistrationService";

export const freeStatusChangeThunk = createAsyncThunk(
  "activityRegistration/freeStatusChange",
  async (id, { rejectWithValue }) => {
    try {
      const req = await freeStatusChange(id);
      if (req.success === false) {
        return rejectWithValue(req);
      }
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const modifyAttendanceThunk = createAsyncThunk(
  "activityRegistration/modifyAttendance",
  async (data, { rejectWithValue }) => {
    try {
      const req = await modifyAttendance(data);
      if (req.success === false) {
        return rejectWithValue(req);
      }
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const paidStatusChangeThunk = createAsyncThunk(
  "activityRegistration/paidStatusChange",
  async (id, { rejectWithValue }) => {
    try {
      const req = await paidStatusChange(id);
      if (req.success === false) {
        return rejectWithValue(req);
      }
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createCertThunk = createAsyncThunk(
  "activityRegistration/createCert",
  async (id, { rejectWithValue }) => {
    try {
      const req = await createCert(id);
      if (req.success === false) {
        return rejectWithValue(req);
      }
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getAllCertsThunk = createAsyncThunk(
  "activityRegistration/createCert",
  async (id, { rejectWithValue }) => {
    try {
      const req = await getAllCerts();
      if (req.success === false) {
        return rejectWithValue(req);
      }
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const revokeCertThunk = createAsyncThunk(
  "activityRegistration/revokeCert",
  async (id, { rejectWithValue }) => {
    try {
      const req = await revokeCertificate(id);
      if (req.success === false) {
        return rejectWithValue(req);
      }
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const attendanceBulk = createAsyncThunk(
  "activityRegistration/attendanceBulk",
  async (id, { rejectWithValue }) => {
    try {
      const req = await attendanceBulkService(id);
      if (req.success === false) {
        return rejectWithValue(req);
      }
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const createMultipleCerts = createAsyncThunk(
  "activityRegistration/createMultipleCerts",
  async (ids, { rejectWithValue }) => {
    try {
      const req = await createMultipleCertsService(ids);
      if (req.success === false) {
        return rejectWithValue(req);
      }
      return req;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
