import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useDispatch} from 'react-redux';
import {resetThunk, verifyResetTokenThunk} from '../../redux/auth/thunks';
import styles from "./auth.module.css";
import { LinkContainer } from "react-router-bootstrap";
import React, { useState } from 'react';

export default function Reset() {
  const [validated, setValidated] = useState(false);
  const [formData, updateFormData] = useState([]);
  const [password2Ok, setPassword2Ok] = useState(true);
  const [passwordRegex, setPasswordRegex] = useState(true);
  const dispatch = useDispatch();

  const handleChange = (e) => {

    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    });
    if (e.target.name === 'password2') {
      if (formData.password !== e.target.value) {
        setPassword2Ok(false)
      } else {
        setPassword2Ok(true)
      }
    }
    if (e.target.name === 'password') {
      let regex = e.target.pattern;
      if (e.target.value.match(regex)) {setPasswordRegex(true)} else {setPasswordRegex(false)} 
      if (formData.password2 !== e.target.value && "password2" in formData) {setPassword2Ok(false)} else {setPassword2Ok(true)}
      
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (password2Ok === false) {alert('las contraseñas deben coincidir'); return} 
    if (passwordRegex === false) {alert('la contraseña debe tener...');  return} 
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else { 
      dispatch(resetThunk(formData))
    }
    setValidated(true);
  };

  return (
    
    <Container fluid className={styles.palmBackground}>
      <Row className={styles.h100}>
        <Card className={`col-md-5 mx-auto my-auto ${styles.loginContainer}`}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Card.Body>
              <Card.Title className={styles.title}>Restablecer contraseña</Card.Title>
              <Card.Subtitle className={`mb-2 text-muted ${styles.subtitle}`}>
                
              </Card.Subtitle>
              <FloatingLabel controlId="floatingPassword" label="Contraseña" className="mb-3">
                <Form.Control
                  required
                  type="password"
                  name="password"
                  placeholder="Password"
                  className= {passwordRegex === false && styles.validatePassword}
                  //Para validar los input se agrega el campo pattern con expresiones regulares
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
                  onChange={handleChange}
                />
                {passwordRegex === false && <p style={{color: "#dc3545"}}>Las contraseñas debe tener...</p>}
                <Form.Control.Feedback />
              </FloatingLabel>
              <FloatingLabel controlId="floatingPassword2" label="Repita contraseña" className="mb-3">
                <Form.Control
                  required
                  type="password"
                  name="password2"
                  placeholder="Password2"
                  className= {password2Ok === false && styles.validatePassword}
                  //Para validar los input se agrega el campo pattern con expresiones regulares
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$"
                  onChange={handleChange}
                />
                {password2Ok === false && <p style={{color: "#dc3545"}}>Las contraseñas no coinciden</p>}
              </FloatingLabel>
              <Col className="text-center mt-4">
                <Button size="lg" type="submit" className={styles.button}>
                  Enviar
                </Button>
              </Col>
            </Card.Body>
          </Form>
        </Card>
      </Row>
    </Container>
  );
}
