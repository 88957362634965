import {useState} from 'react';
import AdminTable from '../../components/tables/Table';
import { Container, Alert } from "react-bootstrap";
import { downloadCertificate, resendEmail } from '../../services/activityRegistrationService';
import { saveAs } from 'file-saver'
import { useDispatch, useSelector } from 'react-redux';
import { revokeCertThunk } from '../../redux/activityRegistration/thunks';

export default function GetCertificates({setRefresh, refresh, bodyData, loading, error, tableHeader, message, size, headersXlsx}) {
  const dispatch = useDispatch()
  const [certMessage, setMessage] = useState(message);
  const [certError, setError] = useState(error);
  const messageActivityReg = useSelector(state => state.activityRegistration.message);
  const [alert, setAlert] = useState(true);

  const download = async (id) => {
    const {data} = await downloadCertificate(id)
    const blob = new Blob([data], { type: 'application/pdf' })
    saveAs(blob, `certificate-${id}.pdf`)
  };

  const sendEmail = async (id) => {
    let email = await resendEmail(id)
    if (email.success === true) {
      setMessage(email.message)
    } else {
      setError(email.message)
    }
  };

  const revoke = async (id) => {
    dispatch(revokeCertThunk(id));
    setRefresh(!refresh)
  };


  const buttons = [
    {
      name: "Delete",
      overlay: "Borrar certificado",
      icon: "ban",
      className: "btnDanger",
      action: revoke,
      confirmMessage: "¿Está seguro de querer borrar este certificado?",
    },
    {
      name: "Reenviar",
      overlay: "Reenviar certificado",
      icon: "envelope",
      action: sendEmail
    },
    {
      name: "Descargar",
      overlay: "Descargar certificado",
      icon: "download",
      action: download,
    },
  ];

    return (
    <>
      {error ? (
        <p>Ha ocurrido un error. Por favor vuelva en intentar en unos minutos</p>
      ) : loading ? (
        <p>Loading</p>
      ) : (
        <Container fluid style={{marginTop: "30px"}} >
            {(messageActivityReg && alert) && 
            <Alert className= 'text-center' variant={messageActivityReg ? 'success' : 'danger'} onClose={() => setAlert(false)}  dismissible>
              {messageActivityReg}
            </Alert>
            }
     <AdminTable
       bodyData={bodyData}
       tableHeader={tableHeader}
       buttons={buttons}
       buttonsWidth={'162px'}
       message={certMessage}
       error={certError}
       size={size}
       headersXlsx={headersXlsx}
     />
     </Container>
   
      )}
    </>
  )
}
