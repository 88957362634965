import {useSelector, useDispatch} from 'react-redux';
import GetActivitiesReg from '../activities/GetActivitiesRegistration'

export default function UserActivities() {
  const dispatch = useDispatch();
  const bodyData = useSelector((state) => state.users.userActivities);
  const loading = useSelector((state) => state.users.certLoading);
  const errorMessage = useSelector((state) => state.users.userError);
  const message = useSelector((state) => state.users.userMessage);

  const tableHeader = [
    { label: "Código", name: "code" },
    { label: "Nombre", name: "activity.title" },
    { label: "Fecha de inicio", name: "activity.startDate" },
    { label: "Presente", name: "attended", positive: 'Presente', negative:'Ausente' },
    { label: "Tipo", name: "activity.paid", positive: 'Paga', negative:'Gratuita' },
    { label: "Estado", name: "status" }
  ];

  const headersXlsx = {
    code: "Código",
    'activity.title': "Nombre",
    'activity.startDate': "Fecha de inicio",
    attended: "Presente",
    "activity.paid": "Pago",
    status: "Estado"
  }

  const headersXlsxAll = {
    code: "Código",
    'activity.title': "Nombre",
    'activity.subtitle': "Subtitulo",
    'activity.startDate': "Fecha de inicio",
    'activity.finishDate': "Fecha de fin",
    attended: "Presente",
    "activity.paid": "Pago",
    status: "Estado",
    type: "Modalidad",
    price: "Precio",
    'activity.country': "Pais",
    'activity.province': "Provincia",
    'activity.postalCode': "Codigo Postal",
    'activity.address': "Direccion",
    'activity.description': "Descricpion",
    'activity.locationLink': "Link Ubicacion",
    'activity.maxParticipants': "Max. participantes",
    'activity.status': "Estado de la actividad",
    'activity.doctors': "Doctores"
  }

  return (
    <>
    <GetActivitiesReg headersXlsx={headersXlsx} headersXlsxAll={headersXlsxAll} bodyData={bodyData} loading={loading} tableHeader={tableHeader} size={5} />
    </>
  );
}
