import React, { useState} from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import TextInput from "./TextInput";
import SelectInput from "./SelectInput";
import RadioInput from "./RadioInput";
import FileInput from "./FileInput";
import FormData from 'form-data';
import { localtimeToUTC } from "../../helpers/dateHelpers";

export default function CreateForm({ formData, action , error, message, titleBtn}) {
  const [alert, setAlert] = useState(true);
  const [validated, setValidated] = useState(false);
  const [alertMessage, setAlertMessage] = useState(message ? message : error);
  const [stateData, updateStateData] = useState(formData.reduce((data, key) => (key.name && { ...data, [key.name]: key.value}), {}));
  const [files, setFiles] = useState([]);

  

  const fileSelectedHandler = (e) => {
    setFiles([ ...files, {file: e.target.files[0], name: e.target.name} ])
  }

  const handleChange = (e) => {
    updateStateData({
      ...stateData,
      [e.target.name]: e.target.value.trim()
    });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setAlertMessage("Por favor, rellena todos los campos");
      setAlert(true);
    } else { 

      Object.keys(stateData).forEach(function(key){
        if(key.includes("Date")){
          stateData[key] = localtimeToUTC(stateData[key]);
        }
      });
      
      if (formData.find(item => item.type === 'file')) {
        let form_data = new FormData();
        files.forEach(file => {
          form_data.append(file.name, file.file);
      });
      Object.keys(stateData).forEach(function(key){
          form_data.append(key, stateData[key]);
      });
        action(form_data)
      } else {
        action(stateData)
      }
     
    }
    setValidated(true);
  };


  function renderSwitch(param, index) {
    switch (param.type) {
      case "text":
      case 'date':
      case 'datetime-local':
      case 'number':
      case 'email':
        return <TextInput data={param} key={index} handleChange={handleChange} />;
      case "select":
        return <SelectInput data={param} key={index} handleChange={handleChange} />;
      case "radio":
        return <RadioInput data={param} key={index} handleChange={handleChange} />;
      case "checkbox":
        return <RadioInput data={param} key={index} handleChange={handleChange} />;
      case "switch":
        return <RadioInput data={param} key={index} handleChange={handleChange} />;
      case "file":
        return <FileInput data={param} key={index} handleChange={fileSelectedHandler} />;
      default:
        return;
    }
  }

  return (
    <>
      {
       
        <Container fluid>
            {(alertMessage && alert) && (
            <Alert className= 'text-center' variant={message ? 'success' : 'danger'} onClose={() => setAlert(false)}  dismissible>
              {alertMessage}
            </Alert>
            )}
          <Row>
            <Col>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  {formData.map((info, index) => {
                    return renderSwitch(info, index);
                  })}
                  <Col xs={12} style={{marginTop: '20px'}}>
                    <Button size="lg" type="submit" className='default-btn'>
                      {titleBtn ? titleBtn : 'Enviar'}
                    </Button>
                  </Col>
                  </Row>
                </Form>
            </Col>
          </Row>
        </Container>
      }
    </>
  );
}
