
import { Container, Nav, Accordion, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faBarcode, faEdit, faMoneyBillAlt, faWindowRestore, faAd, faPlus, faList, faTasks, faUsers, faUser, faCalendar, faHome, faCertificate, faCompressAlt, faArrowsAltH } from '@fortawesome/free-solid-svg-icons';


const menuItems = [
  {
    "label": "USUARIOS",
    "icon": "faUsers",
    "links": [
      { "type": "internal", "icon": "faUser", "label": "Listar usuarios", "path": "/users/getAll" }
    ]
  },
  {
    "label": "ACTIVIDADES",
    "icon": "faCalendar",
    "links": [
      { "type": "internal", "icon": "faTasks", "label": "Listar actividades", "path": "/activities/getAll" },
      { "type": "internal", "icon": "faPlus", "label": "Crear actividad", "path": "/activities/createActivity" }
    ]
  },
  {
    "label": "HOMEPAGE",
    "icon": "faHome",
    "links": [
      { "type": "internal", "icon": "faHome", "label": "Home", "path": "/" },
      { "type": "internal", "icon": "faAd", "label": "Banner", "path": "/homeView/getBanner" },
      { "type": "internal", "icon": "faWindowRestore", "label": "Pop up", "path": "/pop_up" }
    ]
  },
  {
    "label": "CERTIFICADOS",
    "icon": "faCertificate",
    "links": [
      { "type": "internal", "icon": "faList", "label": "Listar certificados", "path": "/certificates/all" }
    ]
  },
  {
    "label": "SUSCRIPCIONES",
    "icon": "faMoneyBillAlt",
    "links": [
      { "type": "internal", "icon": "faEdit", "label": "Editar suscripciones", "path": "/subscriptions/update" }
    ]
  },
  {
    "label": "CODIGOS",
    "icon": "faBarcode",
    "links": [
      { "type": "internal", "icon": "faPlus", "label": "Crear", "path": "/codes/create" },
      { "type": "internal", "icon": "faList", "label": "Listar Códigos", "path": "/codes/activities/all" }
    ]
  }
];

export default function Sidebar({ isOpen, setIsOpen }) {

  const getIcon = (iconName) => {
    switch (iconName) {
      case 'faUser':
        return faUser;
        case 'faUsers':
          return faUsers;
          case 'faBarcode':
      return faBarcode;
    case 'faAd':
      return faAd;
      case 'faCalendar':
        return faCalendar;
      case 'faHome':
        return faHome;
        case 'faList':
          return faList;
        case 'faTasks':
          return faTasks;
      case 'faCertificate':
        return faCertificate;
        case 'faPlus':
          return faPlus;
          case 'faWindowRestore':
            return faWindowRestore;
            case 'faMoneyBillAlt':
              
      return faMoneyBillAlt;
      case 'faEdit':
        return faEdit;
      default:
        return faUser;
    }
  };

  const renderTooltip = (props) => (
    <Tooltip {...props}>
      {props.label}
    </Tooltip>
  );

  return (
    <div className={`sidebar ${isOpen ? '' : 'collapsed'}`}>
      <Accordion>
        {menuItems.map((item, index) => (
          <Accordion.Item key={index} eventKey={index.toString()} style={{ border: 'none' }}>
            <Accordion.Header>
              {!isOpen ? (
                <OverlayTrigger
                  placement="right"
                  overlay={renderTooltip({ label: item.label })}
                >
                  <span><FontAwesomeIcon icon={getIcon(item.icon)} className='fa-sidebar' /></span>
                </OverlayTrigger>
              ) : (
                <>
                  <FontAwesomeIcon icon={getIcon(item.icon)} className='fa-sidebar' />
                  {item.label}
                </>
              )}
            </Accordion.Header>
            <Accordion.Body>
              {item.links.map((link, linkIndex) => (
                <div className={`sidebar-subitem ${!isOpen ? 'collapsed' : ''}`} key={linkIndex}>
                  {link.type === 'internal'
                    ? (
                      <LinkContainer to={link.path}>
                        <Nav.Link>
                          {isOpen
                            ? <>
                                <FontAwesomeIcon icon={getIcon(link.icon)} className='fa-sidebar-subitem' />
                                {link.label}
                              </>
                            : <OverlayTrigger
                                placement="right"
                                overlay={renderTooltip({ label: link.label })}
                              >
                                <span><FontAwesomeIcon icon={getIcon(link.icon)} className='fa-sidebar-subitem' /></span>
                              </OverlayTrigger>
                          }
                        </Nav.Link>
                      </LinkContainer>
                    )
                    : (
                      <a href={link.path}>
                        {isOpen
                          ? <>
                              <FontAwesomeIcon icon={getIcon(link.icon)} className='fa-sidebar-subitem' />
                              {link.label}
                            </>
                          : <OverlayTrigger
                              placement="right"
                              overlay={renderTooltip({ label: link.label })}
                            >
                              <span><FontAwesomeIcon icon={getIcon(link.icon)} className='fa-sidebar-subitem' /></span>
                            </OverlayTrigger>
                        }
                      </a>
                    )
                  }
                </div>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
      <Button 
        onClick={() => setIsOpen(!isOpen)} 
        className="toggle-button-nav"
      >
        <FontAwesomeIcon icon={isOpen ? faCompressAlt : faArrowsAltH} />
      </Button>
    </div>
  );
  
  
  
}