import { createAsyncThunk} from '@reduxjs/toolkit';
import { getBanner, updateBanner, getStatistics } from '../../services/homeViewService'

export const getBannerThunk = createAsyncThunk(
    'homeView/getBanner',
    async (data, {rejectWithValue}) => {
      try {
        const req = await getBanner();
        if (req.success !== true) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const updateBannerThunk = createAsyncThunk(
    'homeView/updateBanner',
    async (data, {rejectWithValue}) => {
      try {
        const req = await updateBanner(data);
        if (req.success !== true) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );
  
  export const getStatisticsThunk = createAsyncThunk(
    'homeView/getStatistics',
    async (data, {rejectWithValue}) => {
      try {
        const req = await getStatistics();
        if (req.success !== true) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );
  


  