import React from 'react'
import Button from 'react-bootstrap/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default function ExportExcel({csvData, fileName, headersXlsx, btnTitle})  {
    let keys = Object.keys(headersXlsx)
    let values = Object.values(headersXlsx)
    let newCsvData = csvData.map(obj => {
        let newObject = {}
        for (const key in keys) {            
           newObject[keys[key]] = keys[key].split(".").length === 2 ? obj[keys[key].split(".")[0]]?.[keys[key].split(".")[1]] : obj[keys[key]]
        }
        return newObject 
    })
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const exportToCSV = (newCsvData, fileName, values) => {
        const ws = XLSX.utils.json_to_sheet(newCsvData);
        const ws1  = XLSX.utils.sheet_add_aoa(ws, [values], { origin: "A1" }); 
        const wb = { Sheets: { 'data': ws1 }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button className='default-btn' style={{marginRight: '10px'}} onClick={(e) => exportToCSV(newCsvData,fileName,values)}>{btnTitle}</Button>
    )
}