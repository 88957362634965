import {toLocalTime} from '../../helpers/dateHelpers';

export default function dataToRender(bodyData) { 
    let formData = [
      {
        label: "Tipo de evento",
        value: bodyData?.type,
        type: "select",
        size: 4,
        name: "type",
        required: true,
        inputOptions: [{value: "virtual", label: "Virtual"}, {value: "presencial", label: "Presencial"}, {value: "mixta", label: "Mixta"}],
      },
      {
        label: "Status",
        value: bodyData?.status,
        type: "select",
        size: 4,
        name: "status",
        required: true,
        inputOptions: [{value: 'pending', label: "Pendiente"}, {value: 'active', label: "Activo"}, {value: 'finished', label: "Finalizado"}, {value: 'cancelled', label: "Cancelado"}]
      },
      {
        label: "¿Quiénes lo puden ver?",
        value: bodyData?.userType,
        type: "select",
        size: 4,
        name: "userType",
        required: true,
        inputOptions: [{value: "authorized", label: "Solo miembros"}, {value: "nonMember", label: "Solo no miembros"}, {value: "all", label: "Todos"}]
      },
      {
        label: "Titulo",
        value: bodyData?.title,
        type: "text",
        size: 6,
        name: "title",
        required: true,
      },
  

        {
          label: "Subtitulo",
          value: bodyData?.subtitle,
          type: "text",
          size: 6,
          name: "subtitle",
          required: true,
        },
        {
          label: "¿Cuantas personas se pueden inscribir? (0 equivale a ilimitado)",
          value: bodyData?.maxParticipants ? bodyData?.maxParticipants : 0,
          type: "number",
          size: 6,
          name: "maxParticipants",
          required: true
        
        },
        {
          label: "Fecha de inicio",
          value: toLocalTime(bodyData?.startDate),
          type: "datetime-local",
          size: 4,
          name: "startDate",
          required: true,
        },
        {
          label: "Fecha de fin",
          value: toLocalTime(bodyData?.finishDate),
          type: "datetime-local",
          size: 4,
          required: true,
          name: "finishDate",
        },
        {
          label: "Texto de fecha",
          value: bodyData?.dateText,
          type: "text",
          size: 4,
          name: "dateText",
          required: true,
        },
        {
          label: "Dirección",
          value: bodyData?.address,
          type: "text",
          size: 4,
          name: "address",
        
        },
        {
          label: "País",
          value: bodyData?.country,
          type: "text",
          size: 4,
          name: "country",
       
        },
        {
          label: "Provincia",
          value: bodyData?.province,
          type: "text",
          size: 4,
          name: "province",
      
        },
        {
          label: "Código Postal",
          value: bodyData?.postalCode,
          type: "text",
          size: 4,
          name: "postalCode",
        },
        {
          label: null,
          value: bodyData?.featured ? true : false,
          type: "switch",
          size: 4,
          name: "featured",
          inputOptions: [{value: true, label: "Actividad destacada"}]
        },


        {
          label: "Disertantes (Separar por coma)",
          value: bodyData?.doctors,
          type: "text",
          size: 12,
          name: "doctors",
       
        },
        {
          label: "Link de ubicacion",
          value: bodyData?.locationLink,
          type: "text",
          size: 4,
          name: "locationLink",
        },
        {
          label: "Link de evento virtual",
          value: bodyData?.virtualLink,
          type: "text",
          size: 4,
          name: "virtualLink",
        },
   
        {
          label: null,
          value: bodyData?.paid ? true : false,
          type: "switch",
          size: 12,
          name: "paid",
          inputOptions: [{value: true, label: "Es un evento pago"}]
        },



        {
          label: "Precio virtual",
          value: bodyData?.virtualPrice ? bodyData?.virtualPrice : 0,
          type: "number",
          size: 4,
          name: "virtualPrice",
          required: true
        },
        {
          label: "Precio",
          value: bodyData?.price ? bodyData?.price : 0,
          type: "number",
          size: 4,
          name: "price",
          required: true
        },


        // {
        //   label: "Descripcion",
        //   value: bodyData?.description,
        //   type: "text",
        //   size: 12,
        //   name: "description",
          
        // },

        {
          label: "Imagen",
          value: bodyData?.imgPath,
          name: 'imgPath',
          type: "file",
          required: true,
          size: 12,
        },
        {
          label: null,
          value: bodyData?.certificate ? true : false,
          type: "switch",
          size: 12,
          name: "certificate",
          inputOptions: [{value: true, label: "Tiene certificado"}]
        },
       
        {
          label: "Tiempo necesario de visualización para emitir certificado Online",
          value: bodyData?.connection_time_for_certificate ? bodyData?.connection_time_for_certificate : 0,
          name: 'connection_time_for_certificate',
          type: "text",
          size: 8,
        },
        {
          label: "header de certificado",
          value: bodyData?.cert_header,
          name: 'cert_header',
          type: "file",
          size: 12,
        },
        {
          label: "footer de certificado",
          value: bodyData?.cert_footer,
          name: 'cert_footer',
          type: "file",
          size: 12,
        },
        
      ];

      return formData

}