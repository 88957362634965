import { toLocalTime } from '../../helpers/dateHelpers';

export default function dataToRender(bodyData) {
    let formData = [
        {
            label: "Nombre",
            value: bodyData?.name,
            type: "text",
            size: 4,
            name: "name",

        },
        {
            label: "Razon",
            value: bodyData?.preferences?.reason,
            type: "text",
            size: 4,
            name: "reason",

        },
        {
            label: "Referencia Externa",
            value: bodyData?.preferences?.external_reference,
            type: "text",
            size: 4,
            name: "external_reference",

        },
        {
            label: "Url de retorno",
            value: bodyData?.preferences?.back_url,
            type: "text",
            size: 4,
            name: "back_url",

        },
        {
            label: "Monto",
            value: bodyData?.preferences?.auto_recurring?.transaction_amount,
            type: "number",
            size: 4,
            name: "transaction_amount",

        }
    ];

    return formData

}