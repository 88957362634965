import moment from 'moment';

export const toLocalTime = (date) => {
    if (moment(date).isValid() && date) {
        return moment(date).local().format('YYYY-MM-DD HH:mm');
    } else {
        return '';
    }
    }

export const toLocalDate = (date) => {
    return moment(date).local().format('YYYY-MM-DD')
    }
export const localtimeToUTC = (date) => {
    return moment(date).utc().format('YYYY-MM-DD HH:mm')
    }
export const localDateToUTC = (date) => {
    return moment(date).utc().format('YYYY-MM-DD')
    }






