import React, { useEffect, useState } from 'react';
import { Container, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getClientsByActivityThunk } from '../../redux/codes/thunks';
import { useParams } from "react-router-dom";
import AdminTable from '../../components/tables/Table';

export default function ClientsByActivity() {
    const { activityId } = useParams();
    const dispatch = useDispatch();
    const bodyData = useSelector((state) => state.codes.all);
    const loading = useSelector((state) => state.codes.allLoading);
    const error = useSelector(state => state.codes.allError?.message);
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        dispatch(getClientsByActivityThunk(activityId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);


    const tableHeader = [
        { label: "Cliente", name: '_id' },
        { label: "Usados", name: 'used' },
        { label: "Disponibles", name: 'available' },
        { label: "Totales", name: 'total' }
    ];

    const buttons = [
        {
          name: "details",
          overlay: "Ver códigos",
          icon: "eye",
          //conditional:{ key:"registeredUsers", opossiteValues: [0] },
          path: `codes/byClientAndActivity/${activityId}`,
        },
      ];

    return (
        <>
        {error ? (
            <p>Ha ocurrido un error. Por favor vuelva en intentar en unos minutos</p>
        ) : loading ? (
            <p>Loading</p>
        ) : (
            <Container fluid>
                <h2 className="text-center">Todos los clientes {bodyData ? bodyData[0].activity : ''}</h2>
                <Container fluid style={{ marginTop: "30px" }} >
                    <AdminTable
                        bodyData={bodyData}
                        buttons={buttons}
                        tableHeader={tableHeader}
                        buttonsWidth={'162px'}
                        size={20}
                    />
                </Container>
            </Container>
        )}
    </>
    );
}
