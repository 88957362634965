import React, { useEffect, useState } from 'react';
import AdminTable from '../../components/tables/Table';
import { Container, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getAllActivitiesThunk, deleteActivityThunk } from '../../redux/activities/thunks';

export default function ListarUsuarios() {
  const dispatch = useDispatch();
  const bodyData = useSelector((state) => state.activities.all);
  const loading = useSelector((state) => state.activities.allLoading);
  const error = useSelector(state => state.activities.allError?.message);
  const activityMessage = useSelector(state => state.activities.activityMessage);
  //let requestSend = false;
  const [refresh, setRefresh] = useState(false)
  const [alert, setAlert] = useState(true);

  const remove = async (id) => {
    dispatch(deleteActivityThunk(id));
    setRefresh(!refresh)
  };

  const tableHeader = [
    { label: "Title", name: "title" },
    { label: "Inscriptos", name: "registeredUsers" },
    { label: "Status", name: "status" },
    { label: "Tipo", name: "type" },
    { label: "Max", name: "maxParticipants" },
    { label: "Inicio", name: "startDate" },
    { label: "Fin", name: "finishDate" },
    { label: "Creado", name: "createdAt" },
    { label: "Pago", name: "paid", positive: 'Paga', negative:'Gratuita' },
  ];

  const headersXlsx = {
    title: "Title",
    registeredUsers: "Inscriptos",
    status: "Status",
    type: "Tipo",
    maxParticipants: "Max",
    startDate: "Inicio",
    finishDate: "Fin",
    paid: "Pago",
    createdAt: "Fecha Creacion"
  }

  const headersXlsxAll = {
    code: "Código",
    title: "Nombre",
    subtitle: "Subtitulo",
    startDate: "Fecha de inicio",
    finishDate: "Fecha de fin",
    attended: "Presente",
    registeredUsers: "Inscriptos",
    paid: "Pago",
    status: "Estado",
    type: "Modalidad",
    price: "Precio",
    country: "Pais",
    province: "Provincia",
    postalCode: "Codigo Postal",
    address: "Direccion",
    description: "Descricpion",
    locationLink: "Link Ubicacion",
    maxParticipants: "Max. participantes",
    doctors: "Doctores"
  }

  const buttons = [
    {
      name: "Delete",
      overlay: "Borrar actividad",
      icon: "ban",
      className: "btnDanger",
      action: remove,
      confirmMessage: "¿Está seguro de querer borrar este usuario?",
    },
    {
      name: "Edit",
      overlay: "Editar actividad",
      icon: "edit",
      path: "activities/edit",
    },
    {
      name: "details",
      overlay: "Ver Usuarios inscriptos",
      icon: "eye",
      conditional:{ key:"registeredUsers", opossiteValues: [0] },
      path: "activities/subscribedUsers",
    },
  ];


  useEffect(() => {
    dispatch(getAllActivitiesThunk());
  }, [refresh])

    return (
    <>
      {error ? (
        <p>Ha ocurrido un error. Por favor vuelva en intentar en unos minutos</p>
      ) : loading ? (
        <p>Loading</p>
      ) : (
        <Container fluid>
           {(activityMessage && alert) && 
            <Alert className= 'text-center' variant={activityMessage ? 'success' : 'danger'} onClose={() => setAlert(false)}  dismissible>
              {activityMessage}
            </Alert>
            }
        <h2 className="text-center">Todas las actividades</h2> 
        <Container fluid style={{marginTop: "30px"}} >
     <AdminTable
       bodyData={bodyData}
       tableHeader={tableHeader}
       buttons={buttons}
       buttonsWidth={'162px'}
       size={20}
       headersXlsx={headersXlsx}
       headersXlsxAll={headersXlsxAll}
     />
     </Container>
     </Container>
      )}
    </>
  )
}
