import { createAsyncThunk} from '@reduxjs/toolkit';
import { refundPayment } from '../../services/billingService';


export const refundPaymentThunk = createAsyncThunk(
    'billing/refundPayment',
    async (data, {rejectWithValue}) => {
      try {
        const req = await refundPayment(data);
        if (req.success === false) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );


  



  


  