import { createSlice, isPending, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
//sacamos los thunks de l archivos y los trabajamos en un archivo aparte
import { getAllCodesThunk, getCodeThunk, createCodeThunk, getClientsByActivityThunk, getCodesByClientsAndActivityThunk } from './thunks'
import {errorHandler} from '../utils/errorHandler'
const initialState = {
  all: [],
  allLoading: true,
  allError: null,
  allMessage: null,
  ////
  code: {},
  codeLoading: false,
  codeError: null,
  codeMessage: null,
  ////
  allClients: [],
  allClientsLoading: true,
  allClientsError: null,
  allClientsMessage: null,
  ////
  allCodes: [],
  allCodesLoading: true,
  allCodesError: null,
  allCodesMessage: null,
};

export const codesSlice = createSlice({
  name: 'codes',
  initialState,
  reducers: {
    swipeCodeData: (state) => {
      state.codeError = null;
      state.codeMessage = null;
    }
  },
  extraReducers: builder => {
    builder
      .addMatcher(isPending(getAllCodesThunk, getClientsByActivityThunk, getClientsByActivityThunk), (state) => {
        state.allLoading = true;
        state.allError = null;
        state.allMessage = null;
      })
   
      .addMatcher(isPending(getCodesByClientsAndActivityThunk), (state) => {
        state.allCodesLoading = true;
        state.allCodesError = null;
        state.allCodesMessage = null;
      })
      .addMatcher(
        isPending(createCodeThunk, getCodeThunk),
        (state) => {
          state.codeLoading = true;
          state.codeError = false;
          state.codeMessage = null;
        }
      )
      .addMatcher(isFulfilled(getAllCodesThunk, getClientsByActivityThunk), (state, action) => {
        state.allLoading = false;
        state.allError = false;
        state.allMessage = action.payload.message;
        state.all = action.payload.codes;
      })
 
      .addMatcher(isFulfilled(getCodesByClientsAndActivityThunk), (state, action) => {
        state.allCodesLoading = false;
        state.allCodesError = false;
        state.allCodesMessage = action.payload.message;
        state.allCodes = action.payload.codes;
      })
      .addMatcher(
        isFulfilled(createCodeThunk, getCodeThunk),
        (state, action) => {
          state.codeLoading = false;
          state.codeError = null;
          //Este mensaje debe venir del servidor
          state.codeMessage = action.payload.message;
          state.code = action.payload.activity;
        }
      )
      .addMatcher(
        isRejectedWithValue(getAllCodesThunk),
        (state, action) => {
          errorHandler(action.payload);
          state.allLoading = false;
          state.allError = {
            message:
              "Ha ocurrido un error, por favor intente nuevamente en unos momentos",
          };
        }
      )
      .addMatcher(
        isRejectedWithValue(getClientsByActivityThunk),
        (state, action) => {
          errorHandler(action.payload);
          state.allClientsLoading = false;
          state.allClientsError = {
            message:
              "Ha ocurrido un error, por favor intente nuevamente en unos momentos",
          };
        }
      )
      .addMatcher(
        isRejectedWithValue(getCodesByClientsAndActivityThunk),
        (state, action) => {
          errorHandler(action.payload);
          state.allCodesLoading = false;
          state.allCodesError = {
            message:
              "Ha ocurrido un error, por favor intente nuevamente en unos momentos",
          };
        }
      )
      .addMatcher(
        isRejectedWithValue(
          createCodeThunk,
          getCodeThunk
        ),
        (state, action) => {
          errorHandler(action.payload);
          state.codeLoading = false;
          state.codeError = {
            message:
              "Ha ocurrido un error, por favor intente nuevamente en unos momentos",
          };
          state.codeData = {};
        }
      );
  }
});

export const { swipeCodeData } = codesSlice.actions;



export default codesSlice.reducer;
