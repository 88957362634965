import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCertsThunk } from '../../redux/activityRegistration/thunks';
import GetCertificates from '../../components/certificates/GetCertificates';

export default function GetAllCertificates() {
  const dispatch = useDispatch();
  const bodyData = useSelector((state) => state.activityRegistration.all);
  const loading = useSelector((state) => state.activityRegistration.loading);
  const error = useSelector(state => state.activityRegistration.error?.message);
  const [refresh, setRefresh] = useState(false)

 
  useEffect(() => {
    dispatch(getAllCertsThunk());
  }, [refresh])

  const tableHeader = [
    { label: "Código", name: "code" },
    { label: "Status", name: "status" },
    { label: "Nombres", name: "user.name" },
    { label: "Apellidos", name: "user.lastName" },
    { label: "Title", name: "activity.title" },
    { label: "Fecha", name: "activity.startDate" },
    { label: "Creado", name: "createdAt" },
  ];

  const headersXlsx = {
    code: "Código",
    status: "Status",
    "user.name": "Nombres",
    "user.lastName": "Apellidos",
    "activity.title": "Title",
    "activity.startDate": "Fecha",
    createdAt: "Creado"
  }


    return (
    <>
      <GetCertificates headersXlsx={headersXlsx} setRefresh={setRefresh} refresh={refresh} bodyData={bodyData} loading={loading} error={error} tableHeader={tableHeader} />
    </>
  )
}

