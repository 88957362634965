import { FloatingLabel, Form, Col, Image } from "react-bootstrap";

export default function FileInput({data, handleChange}) {
   let {label, value, type, size, name} = data;
    return (
        <Col xs={size}>
        <Form.Label>{label}</Form.Label>
            <Form.Control
              type={type}
              name={name}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">
            </Form.Control.Feedback>
         {value &&
          <Image style={{maxHeight: '200px', maxWidth: '400px'}} fluid  src={process.env.REACT_APP_PUBLIC_IMG_URL + '/' + value} />
         }
        </Col>
    )
  }
  