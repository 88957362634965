import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
//sacamos los thunks de l archivos y los trabajamos en un archivo aparte
import {  re_subscribe_thunk, getUserThunk, updateUserThunk, getAllUsersThunk, updateUserStatusThunk, userDetailsThunk } from './thunks'
import {  createCertThunk, freeStatusChangeThunk as freeActivityStatusChangeThunk, modifyAttendanceThunk, paidStatusChangeThunk  } from '../activityRegistration/thunks'
import { refundPaymentThunk } from '../billing/thunks'
import {errorHandler} from '../utils/errorHandler'
const initialState = {
  users: [],
  usersError: null,
  usersMessage: null,
  usersLoading: true,
  //////
  user: {},
  userError: null,
  userMessage: null,
  userLoading: true,
  userActivities: [],
  userBilling: [],
  /////
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    changeLoading(state) {
      state.usersLoading = state
    },
  },
  extraReducers: builder => {
    builder
    .addCase(updateUserStatusThunk.fulfilled, (state, action) => { 
      state.users = state.users.map(user => {
        if (user._id === action.payload.user._id) {
          user.membershipStatus = action.payload.user.membershipStatus;
        }
        return user;
      }
      );
    })

    .addCase(userDetailsThunk.fulfilled, (state, action) => { 
      state.userActivities = action.payload.activities;
      state.userBilling = action.payload.billing;
    })

    .addCase(paidStatusChangeThunk.fulfilled, (state, action) => {
      state.userBilling = state.userBilling.map(billing => {
        if (billing._id === action.payload.activityReg.invoice._id) {
          billing.status = action.payload.activityReg.invoice.status;
        }
        return billing;
      }
      );
      state.userActivities = state.userActivities.map(activity => {
        if (activity._id === action.payload.activityReg._id) {
          activity = action.payload.activityReg;
        }
        return activity;
      });
    })
    
    


    .addCase(createCertThunk.fulfilled, (state, action) => {
      state.userMessage = action.payload.message;
    })

 
    .addMatcher( 
      isPending(re_subscribe_thunk, getUserThunk, updateUserThunk, userDetailsThunk),
      (state) => {
        state.userLoading = true;
        state.userError = null;
        state.userMessage = null;
      }
    )

    .addMatcher( 
      isPending(getAllUsersThunk),
      (state) => {
        state.usersLoading = true;
        state.usersError = null;
        state.usersMessage = null;
      }
    )

    .addMatcher(
      isFulfilled(re_subscribe_thunk, getUserThunk, updateUserThunk, updateUserStatusThunk, userDetailsThunk),
      (state, action) => {
        state.userLoading = false;
        state.userError = null;
        state.user = action.payload.user; 
        state.userMessage= action.payload.message;
      }
    )


    .addMatcher(
      isFulfilled(getAllUsersThunk),
      (state, action) => {
        state.usersLoading = false;
        state.usersError = null;
        state.users = action.payload.users; 
        state.message = null
      }
    )

    .addMatcher( 
      isFulfilled(freeActivityStatusChangeThunk, modifyAttendanceThunk),
      (state, action) => {
        state.userActivities = state.userActivities.map(activity => {
          if (activity._id === action.payload.activityReg._id) {
            activity.attended = action.payload.activityReg.attended;
            activity.status = action.payload.activityReg.status;
          }
          return activity;
        })
        state.userMessage = action.payload.message;
      }
    )

    .addMatcher( 
      isFulfilled(refundPaymentThunk),
      (state, action) => {
        state.userBilling = state.userBilling.map(billing => {
          if (billing._id === action.payload.invoice._id) {
            billing = action.payload.invoice;
          }
          return billing;
        }
        );
      }
    )


   .addMatcher(
      isRejectedWithValue(re_subscribe_thunk, getUserThunk, updateUserThunk, userDetailsThunk),
      (state, action) => {
        errorHandler(action.payload)
        state.userLoading = false;
        //el mensaje deberia venir de la base del server
        state.userError = {message: 'Ha ocurrido un error, por favor intente nuevamente en unos momentos'};
      }
    )

    .addMatcher(
      isRejectedWithValue(getAllUsersThunk),
      (state, action) => {
        errorHandler(action.payload)
        state.usersLoading = false;
        //el mensaje deberia venir de la base del server
        state.usersError = {message: 'Ha ocurrido un error, por favor intente nuevamente en unos momentos'};
      }
    )
  }
});



export const { changeLoading } = usersSlice.actions;
export default usersSlice.reducer;
