import React, { useEffect } from 'react';
import AdminTable from '../tables/Table'; 
import {useSelector, useDispatch} from 'react-redux';
import { refundPaymentThunk } from '../../redux/billing/thunks';
import { downloadBilling } from '../../services/billingService';
import { saveAs } from 'file-saver'

export default function UserActivities({bodyData, loading, error, message, size, setBackdropShow}) {
  const dispatch = useDispatch();

  const download = async (id) => {
    setBackdropShow(true)
    const {data} = await downloadBilling(id)
    const blob = new Blob([data], { type: 'application/pdf' })
    saveAs(blob, `factura-${id}.pdf`)
    setBackdropShow(false)
  };

  const tableHeader = [
    { label: "Código", name: "code" },
    { label: "Tipo de factura", name: "type" },
    { label: "Actividad", name: "activity.title" },
    { label: "Descripcion", name: "description" },
    { label: "Estado", name: "status" },
    { label: "Monto", name: "amount_charged" },
    { label: "Fecha de pago", name: "updatedAt", formatterDate: true },
  ];

  const headersXlsx = {
    code: "Código",
    type: "Tipo de factura",
    "activity.title": "Actividad",
    description: "Descripcion",
    status: "Estado",
    amount_charged: "Monto"
  }

  const headersXlsxAll = {
    code: "Código",
    cae: "Numero de cae",
    hf_id: "HF ID",
    active: "Activa",
    type: "Tipo de factura",
    description: "Descripcion",
    status: "Estado",
    amount_charged: "Monto",
    createdAt: "Fecha Creacion",
    nextRetryDate: "Proxima fecha de pago",
    payment_type: "Tipo de pago",
    "activity.title": "Nombre Actividad"
  }

  const refund =  (id) => {
    dispatch(refundPaymentThunk(id));
  }


  const buttons = [
    {
    name: "Devolver dinero",
    overlay: "Devolver dinero",
    icon: "undo",
    conditional: { key: "status", values: ["approved"]},
    className: 'btnDanger',
    action: refund,
    confirmMessage: "¿Está seguro de realizar esta acción?",
   },
   {
    name: "Descargar Factura",
    overlay: "Descargar Factura",
    icon: "download",
    conditional: { key: "status", values: ["processed", "approved"]},
    action: download
   },
  ];

  return (
    <>
      {error ? (
        <p>Ha ocurrido un error. Por favor vuelva en intentar en unos minutos</p>
      ) : loading ? (
        <p>Loading</p>
      ) : (
        <AdminTable
          bodyData={bodyData}
          tableHeader={tableHeader}
          buttons={buttons}
          size={size}
          headersXlsx={headersXlsx}
          headersXlsxAll={headersXlsxAll}
        />
      )}
    </>
  );
}
