import axios from "axios";
import { authHeader } from './authHeader'
//SIEMPRE TOMAR LA API_URL DE LOS AMBIENTES
import { api_url } from '../config'


export const getPopup = async () => {
  try {
    let options = {
      headers: {
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${api_url}/pop_ups/getOne`,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};


export const updatePopup = async (data) => {
  try {
   
    let options = {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        ...authHeader()
      }
    };
    const response = await axios.put(
      `${api_url}/pop_ups/edit`,
      data,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

export const deletePopUp = async (data) => {
  try {
   
    let options = {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        ...authHeader()
      }
    };
    const response = await axios.delete(
      `${api_url}/pop_ups/delete`,
      data,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};