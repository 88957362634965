import axios from "axios";
import { authHeader } from "./authHeader";
//SIEMPRE TOMAR LA API_URL DE LOS AMBIENTES
import { api_url } from '../config'

export const processActivityPayment = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader(),
      },
    };
    const response = await axios.get(
      `${api_url}/billing/proccessActivityPayment/${data.payment_type}/${data.invoiceId}`,
      options
    );
    return response.data
  } catch (e) {
    return e;
  }
};

export const refundPayment = async (id) => {
    try {
      let options = {
        headers: {
          "content-type": "application/json",
          ...authHeader(),
        },
      };
      const response = await axios.get(
        `${api_url}/billing/refundPayment/${id}`,
        options
      );
      return response.data
    } catch (e) {
      return e;
    }
  };


  export const downloadBilling = async (id) => {
    try {
      let options = {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...authHeader(),
        },
        responseType: 'arraybuffer'
      };
      const response = await axios.get(
        `${api_url}/billing/downloadInvoice/${id}`,
        options
      );
      return response;
    } catch (e) {
      return e;
    }
  };
  

