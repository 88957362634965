import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
//sacamos los thunks de l archivos y los trabajamos en un archivo aparte
import { loginThunk, registerThunk, verifyResetTokenThunk, forgotThunk, resetThunk } from './thunks'

const initialState = {
  data: {},
  token: localStorage.getItem('token') ?  localStorage.getItem('token') : null,
  error: false,
  eDescription: {}
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut(state) {
      localStorage.removeItem('token');
      state.token = null;
      state.data = {}
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isPending(
          loginThunk,
          registerThunk,
          verifyResetTokenThunk,
          forgotThunk,
          resetThunk
        ),
        (state) => {
          state.loading = true;
          state.error = false;
          state.errorDescription = {};
        }
      )

      .addMatcher(
        isFulfilled(loginThunk, registerThunk, resetThunk),
        (state, action) => {
          state.loading = false;
          state.error = false;
          state.eDescription = {};
          state.data = action.payload.data;
          state.token = action.payload.token;
          localStorage.setItem("token", action.payload.token);
        }
      )

      .addMatcher(isFulfilled(verifyResetTokenThunk, forgotThunk), (state) => {
        state.loading = false;
        state.error = false;
        state.eDescription = {};
      })

      .addMatcher(
        isRejectedWithValue(
          loginThunk,
          registerThunk,
          verifyResetTokenThunk,
          forgotThunk,
          resetThunk
        ),
        (state, action) => {
          state.loading = false;
          state.error = true;
          state.user = {};
          state.token = null;
          state.eDescription = action.payload;
        }
      );
  },
});

export const { logOut } = authSlice.actions;
export default authSlice.reducer;

