import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useDispatch, useSelector} from 'react-redux';
import {loginThunk} from '../../redux/auth/thunks';
import styles from "./auth.module.css";
import { LinkContainer } from "react-router-bootstrap";
import React, { useState } from 'react';

export default function Login() {
    const [validated, setValidated] = useState(false);
    const [formData, updateFormData] = useState([]);
    const dispatch = useDispatch();
    const handleChange = (e) => {
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value.trim()
      });
    };

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else { 
        dispatch(loginThunk(formData))
      }
      setValidated(true);
    };

    let error= useSelector(state => state.auth.eDescription);

  return (
    <Container fluid className={styles.palmBackground}>
      <Row className={styles.h100}>
        <Card className={`col-md-5 mx-auto my-auto ${styles.loginContainer}`}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Card.Body>
              <Card.Title className={styles.title}>LOGIN</Card.Title>
              <Card.Subtitle className={`mb-2 ${styles.subtitle}`}>
              {error.message} 
              </Card.Subtitle>
              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className="mb-3"
              >
                <Form.Control
                  required
                  type="email"
                  name="email"
                  placeholder="name@example.com"
                  onChange={handleChange}
                />
                <Form.Control.Feedback />
              </FloatingLabel>
              <FloatingLabel controlId="floatingPassword" label="Contraseña" className="mb-3">
                <Form.Control
                  required
                  type="password"
                  name="password"
                  placeholder="Password"           
                  onChange={handleChange}
                />
                  <Form.Control.Feedback type="invalid">
                  </Form.Control.Feedback>
              </FloatingLabel>   
              <Col>
                <LinkContainer to="/forgot">
                  <Card.Link>¿Olvido su contraseña?</Card.Link>
                </LinkContainer>
              </Col>

              <Col className="text-center mt-4">
                <Button size="lg" type="submit" className={styles.button}>
                  Enviar
                </Button>
                {/* <Col className="mt-3">
                  <LinkContainer to="/register">
                    <Card.Link>¿No tienes cuenta?</Card.Link>
                  </LinkContainer>
                </Col> */}
              </Col>
            </Card.Body>
          </Form>
        </Card>
      </Row>
    </Container>
  );
}
