import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
//sacamos los thunks de l archivos y los trabajamos en un archivo aparte
import { getPopupThunk, updatePopupThunk, deletePopUpThunk } from './thunks'
import {errorHandler} from '../utils/errorHandler'
const initialState = {
  loading: true,
  imgPath: null,
  imgPathMobile: null,
  visible: false,
  error: null,
  errorDescription: {},
  message: null
};

export const pop_upsSlice = createSlice({
  name: 'pop_ups',
  initialState,
  extraReducers: builder => {
    builder
    .addMatcher( 
      isPending(getPopupThunk, updatePopupThunk, deletePopUpThunk ),
      (state) => {
        state.loading = true;
        state.error = null;
        state.errorDescription = {};
        state.message = null;
      }
    )
 
    .addMatcher(
      isFulfilled(getPopupThunk, updatePopupThunk, deletePopUpThunk ),
      (state, action) => {
        console.log(action.payload)
        state.loading = false;
        state.imgPath = action.payload.pop_up?.imgPath;
        state.imgPathMobile = action.payload.pop_up?.imgPathMobile;
        state.message = action.payload.message;
        state.visible = action.payload.pop_up?.visible;
      }
    )

   .addMatcher(
      isRejectedWithValue(getPopupThunk, updatePopupThunk, deletePopUpThunk ),
      (state, action) => {
        errorHandler(action.payload)
        state.loading = false;
        state.errorDescription = action.payload;
        state.error = {
          message: action.payload.message,
        } 
      }
    )
  }
});



export default pop_upsSlice.reducer;
