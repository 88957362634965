import { createAsyncThunk} from '@reduxjs/toolkit';
import { login, register, verifyResetToken, forgot, resetPassword } from '../../services/authService'

export const loginThunk = createAsyncThunk(
    'user/loginStatus',
    async (data, {rejectWithValue}) => {
      try {
        const req = await login(data);
        if (req.success === false) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );
  
  export const registerThunk = createAsyncThunk(
    'user/registerStatus',
    async (data, {rejectWithValue}) => {
      try {
        const req = await register(data);
        if (req.response?.status === 400) { 
           return rejectWithValue(req.response.data)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const forgotThunk = createAsyncThunk(
    'user/forgotStatus',
    async (data, {rejectWithValue}) => {
      try {
        const response = await forgot(data);
        return response.data;
      } catch (e) {
        return rejectWithValue(e.response.data)
      }
    }
  );

  export const verifyResetTokenThunk = createAsyncThunk(
    'user/verifyResetTokenStatus',
    async (data, {rejectWithValue}) => {
      try {
        const response = await verifyResetToken(data);
        return response.data;
      } catch (e) {
        return rejectWithValue(e.response.data)
      }
    }
  );

  export const resetThunk = createAsyncThunk(
    'user/resetPasswordStatus',
    async (data, {rejectWithValue}) => {
      try {
        const response = await resetPassword(data);
        return response.data;
      } catch (e) {
        return rejectWithValue(e.response.data)
      }
    }
  );

  


  