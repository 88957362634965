export default function dataToRender(bodyData, activitiesOptions) { 
    let formData = [
        {
            label: "Actividad",
            type: "select",
            value: bodyData?.activity?._id,
            size: 4,
            inputOptions: activitiesOptions,
            name: "activity",
            required: true,
        },
        {
          label: "Cliente",
          value: bodyData?.client,
          type: "text",
          size: 4,
          name: "client",
          required: true
        },
        {
          label: "Cantidad",
          value: bodyData?.amount ? bodyData?.amount : 0,
          type: "number",
          size: 4,
          name: "amount",
          required: true
        }
      ];
      return formData
}