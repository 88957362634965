import { createAsyncThunk} from '@reduxjs/toolkit';
import { updateSubscription, getSubscription } from '../../services/subscriptionsService'

export const getSubscriptionThunk = createAsyncThunk(
    'subscriptions/getSubscription',
    async (data, {rejectWithValue}) => {
      try {
        const req = await getSubscription();
        if (!req.success) { 
          return rejectWithValue(req)
        }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );


  export const updateSubscriptionThunk = createAsyncThunk(
    'activities/createActivity',
    async (data, {rejectWithValue}) => {
      try {
        const req = await updateSubscription(data);
        if (!req.success) { 
          return rejectWithValue(req)
        }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  

  


  