import { createSlice, isPending, isRejectedWithValue, isFulfilled } from '@reduxjs/toolkit';
import { getSubscriptionThunk, updateSubscriptionThunk } from './thunks'

const initialState = {
    subscription: {},
    loading: false,
    error: null,
    message: null
};

export const subscriptionsSlice = createSlice({
    name: 'subscriptions',
    initialState,
    extraReducers: builder => {
        builder
            .addMatcher(isPending(getSubscriptionThunk, updateSubscriptionThunk), (state, action) => {
                state.loading = true;
                state.error = null;
                state.message = null;
            })
            .addMatcher(isFulfilled(getSubscriptionThunk, updateSubscriptionThunk), (state, action) => {
                state.loading = false;
                state.error = false;
                state.message = action.payload.message;
                state.subscription = action.payload.subscription;
            })
           /* .addCase(getSubscribedUsersThunk.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.subscribedUsersMessage = action.payload.message;
                state.subscribedUsers = action.payload.subscription;
            })*/
            .addMatcher(
                isRejectedWithValue(getSubscriptionThunk, updateSubscriptionThunk),
                (state, action) => {
                    state.loading = false;
                    state.error = true
                    state.message = "Ha ocurrido un error, por favor intente nuevamente en unos momentos"
                }
            )
    }
});


export default subscriptionsSlice.reducer;
