import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userDetailsThunk, updateUserThunk, re_subscribe_thunk } from '../../redux/users/thunks';
import { useParams } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import CreateForm from "../../components/forms/CreateForm";
import renderInstructions from './userData';
import renderInstructionsSubscription from './UserDataSubscription';
import UserActivities from '../../components/userDetails/UserActivities';
import UserBilling from '../../components/userDetails/UserBilling';
import UserCertificates from '../../components/userDetails/UserCertificates';
import Backrop from "../../components/backdrop/Backdrop"

export default function UserDetails() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const bodyData = useSelector(state => state.users.user);
  const loading = useSelector(state => state.users.userLoading);
  const error = useSelector(state => state.users.userError?.message);
  const message = useSelector(state => state.users.userMessage);
  let requestSend = false;
  const dataToRender = renderInstructions(bodyData);
  const dataToRenderSubscription = renderInstructionsSubscription(bodyData);
  const [backdropShow, setBackdropShow] = useState(false)

  useEffect(() => {
    if (!requestSend) {
      dispatch(userDetailsThunk(id));
      requestSend = true;
    }
  }, [])


  const formAction = (dataToSubmite) => {
    dispatch(updateUserThunk({ ...dataToSubmite, id }));
  }

  const re_subscribe = () => {
    dispatch(re_subscribe_thunk(bodyData._id));
  }

  const formActionSubscription = (dataToSubmit) => {
    let subNewData = {
      chargedAmount: dataToSubmit["chargedAmount"],
      lastCharged: dataToSubmit["lastCharged"],
      type: 'wire',
      invoice: dataToSubmit["invoice"]
    }
    let subData = Object.assign({}, bodyData.subscription, subNewData)
    let copyData = Object.assign({}, bodyData, { subscription: subData })
    dispatch(updateUserThunk({ ...copyData, id }));
  }

  const changePaymentType = () => {
    let subNewData = {
      type: 'wire'
    }
    let subData = Object.assign({}, bodyData.subscription, subNewData)
    let copyData = Object.assign({}, bodyData, { subscription: subData })
    dispatch(updateUserThunk({ ...copyData, id }));
  }
  return (
    <>
      {loading ? (
        <p>Loading</p>
      ) : (
        <Container>
          <h2 className="text-center">
            Editar a {bodyData.name} {bodyData.lastName}
          </h2>
          <Container
            style={{ marginTop: "30px" }}>
            <CreateForm
              formData={dataToRender} action={formAction}
              message={message} error={error} />
          </Container>
            {
              bodyData.subscription.type === "credit"
                ?
                <Container style={{ marginTop: "30px" }}>
                  {bodyData.subscription?.lastCharged ? <div>Fecha ultimo pago: {bodyData.subscription.lastCharged.substring(0, 10)}</div> : null}
                  {bodyData.subscription?.chargedAmount ? <div>Ultimo monto cobrado: ${bodyData.subscription.chargedAmount}</div> : null}
                  {bodyData.subscription?.link ? <div>Link de subscripcion: {bodyData.subscription.link}</div> : null}
                {bodyData.subscripion?.status !== "authorized"
                ?
                <>
                {/* <Button size="md" type="submit" className='default-btn' style={{marginTop: '15px'}} onClick={re_subscribe}>
                Regenerar link de pago
               </Button> */}
               <Button size="md" type="submit" className='default-btn' style={{marginTop: '15px', marginLeft: '15px'}} onClick={changePaymentType}>
                Cambiar forma de pago a transferencia
               </Button>
               </>
               :
               null
                }
                </Container>
                :
                <Container style={{ marginTop: "30px" }}>
                  <CreateForm formData={dataToRenderSubscription} titleBtn={"Procesar pago de subscripción"} action={formActionSubscription} />
                </Container>

            }
          < Container style={{ marginTop: "30px" }}>
            <h1 className='text-center'>            Actividades</h1>
            <UserActivities
            />
          </Container>
          <Container style={{ marginTop: "30px" }}>
            <h1
              className='text-center'>Facturación</h1>
            <UserBilling setBackdropShow={setBackdropShow} />
          </Container>
          <Backrop show={backdropShow} text={'Descargando...'} />
        </Container>
      )}
    </>
  );
}
