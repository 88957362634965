import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function Backrop({show, text}) {

  return (
    <>
     { /*<Button variant="primary" >
        Launch demo modal
      </Button>
  */}
      <Modal show={show} >
        <Modal.Header>
          <Modal.Title>{text}</Modal.Title>
        </Modal.Header>
      </Modal>
    </>
  );
}
