export default function dataToRender(bodyData) { 
    let formData = [
        {
          label: "Nombre",
          value: bodyData?.name,
          type: "text",
          size: 4,
          name: "name",
          required: true,
        },
        {
          label: "Apellido",
          value: bodyData?.lastName,
          type: "text",
          size: 4,
          name: "lastName",
          required: true,
        },
        {
          label: "email",
          value: bodyData?.email,
          type: "email",
          size: 4,
          name: "email",
          required: true,
        },
        {
          label: "Tipo de membresía",
          type: "select",
          value: bodyData?.membershipStatus,
          size: 4,
          inputOptions: [{value: "nonMember", label: "No miembro"}, {value: "pending", label: "Miembro sin confirmar"},{value: "authorized", label: "miembro"},{value: "cancelled", label: "Miembro bloqueado"},{value: "review", label: "En revisón"}],
          name: "membershipStatus",
          required: true,
        },
        {
          label: "Categoría",
          type: "select",
          value: bodyData?.category,
          size: 4,
          inputOptions: [{value: "Títular", label: "Títular"}, {value: "Adherente", label: "Adherente"},{value: "Honorario", label: "Honorario"}],
          name: "category"
        },
        {
          label: "Socio transferido del sistema anterior.",
          value: bodyData?.transferred,
          type: "switch",
          size: 4,
          name: "transferred",
          inputOptions: [{value: true}]
        },
        {
          label: "Es socio con mas de un año de antiguedad?",
          value: bodyData?.master,
          type: "switch",
          size: 4,
          name: "master",
          inputOptions: [{value: true}]
        },
        {
          label: "Titulo",
          value: bodyData?.title,
          type: "text",
          size: 4,
          name: "title",
          required: true,
        },
        {
          label: "DNI",
          value: bodyData?.dni,
          type: "text",
          size: 4,
          name: "dni",
          required: true,
        },
      
        {
          label: "Telefono",
          value: bodyData?.phone,
          type: "text",
          size: 4,
          name: "phone",
          required: true,
        },
        {
          label: "Telefono fijo",
          value: bodyData?.phoneLine,
          type: "text",
          size: 4,
          name: "phone"
        },
        {
          label: "Rol",
          type: "select",
          value: bodyData?.role,
          size: 4,
          inputOptions: [{value: "user", label: "Usuario"}, {value: "admin", label: "Administrador"}],
          name: "role",
          required: true,
        },
        {
          label: "Categoria",
          type: "select",
          value: bodyData?.role,
          size: 4,
          inputOptions: [{value: "Titular", label: "Titular"}, {value: "Suplente", label: "Suplente"}],
          name: "role",
          required: true,
        },
        {
          label: "Fecha de nacimiento",
          value: bodyData?.dateOfBirth?.substring(0, 10),
          type: "date",
          size: 4,
          name: "birthDate"
        },
        {
          label: "Dirección",
          value: bodyData?.address,
          type: "text",
          size: 4,
          name: "address",
          required: true,
        },
        {
          label: "Localidad",
          value: bodyData?.zone,
          type: "text",
          size: 4,
          name: "zone",
          required: true,
        },
        {
          label: "Código Postal",
          value: bodyData?.postalCode,
          type: "text",
          size: 4,
          name: "postalCode",
          required: true,
        },
        {
          label: "País",
          value: bodyData?.country,
          type: "text",
          size: 4,
          name: "country",
          required: true,
        },
        {
          label: "Provincia",
          value: bodyData?.province,
          type: "text",
          size: 4,
          name: "province",
          required: true,
        },
     
        {
          label: "Especialidad",
          value: bodyData?.specialty,
          type: "text",
          size: 4,
          name: "specialty",
          required: true,
        },
        {
          label: "Profesión",
          value: bodyData?.profession,
          type: "text",
          size: 4,
          name: "profession",
          required: true,
        },
        {
          label: "Lugar de trabajo",
          value: bodyData?.jobAddress,
          type: "text",
          size: 4,
          name: "jobAddress",
          required: true,
        },
        // {
        //   label: "Dirección de trabajo",
        //   value: bodyData?.jobAddress,
        //   type: "text",
        //   size: 4,
        //   name: "jobAddress",
        // },
        {
          label: "Es residente",
          value: bodyData?.resident,
          type: "switch",
          size: 4,
          name: "resident",
          inputOptions: [{value: true}]
        },
        {
          label: "Aval 1",
          value: bodyData?.aval1,
          type: "text",
          size: 4,
          name: "aval1",
        },
        {
          label: "Aval 2",
          value: bodyData?.aval2,
          type: "text",
          size: 4,
          name: "aval2",
        },
        {
          label: "Número de socio",
          value: bodyData?.memberNumber,
          type: "text",
          size: 4,
          name: "memberNumber",
        },
        {
          label: "Socio desde",
          value: bodyData?.dateOfStart,
          type: "text",
          size: 4,
          name: "dateOfStart",
        },
      ];

      return formData

}