import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
//sacamos los thunks de l archivos y los trabajamos en un archivo aparte
import { getAllCertsThunk, revokeCertThunk, attendanceBulk, createCertThunk } from './thunks'
import {errorHandler} from '../utils/errorHandler'

const initialState = {
  all: [],
  error: null,
  message: null,
  loading: true,
  fileDownload: null
};

export const activityRegistrationSlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: builder => {
    builder

    .addMatcher( 
      isPending(getAllCertsThunk, revokeCertThunk, createCertThunk),
      (state) => {
        state.loading = true;
        state.error = null;
        state.message = null;
      }
    )
    .addMatcher(
      isFulfilled(getAllCertsThunk, attendanceBulk),
      (state, action) => {
        state.loading = false;
        state.error = null;
        state.all = action.payload.activityRegistration;
      }
    )
    .addMatcher(
      isFulfilled(revokeCertThunk, attendanceBulk, createCertThunk),
      (state, action) => {
        state.loading = false;
        state.error = null;
        state.message= action.payload.message;
      }
    )

   .addMatcher(
      isRejectedWithValue(getAllCertsThunk, revokeCertThunk, attendanceBulk),
      (state, action) => {
        errorHandler(action.payload)
        state.loading = false;
        //el mensaje deberia venir de la base del server
        state.error = {message: 'Ha ocurrido un error, por favor intente nuevamente en unos momentos'};
      }
    )

  }
});




export default activityRegistrationSlice.reducer;
