import AdminTable from "../tables/Table";
import { useDispatch } from "react-redux";
import {
  createMultipleCerts,
  attendanceBulk,
  createCertThunk,
  freeStatusChangeThunk,
  modifyAttendanceThunk,
  paidStatusChangeThunk,
} from "../../redux/activityRegistration/thunks";

export default function GetActivities({
  bodyData,
  loading,
  errorMessage,
  message,
  tableHeader,
  size,
  headersXlsx,
  headersXlsxAll
}) {
  const dispatch = useDispatch();

  const paidStatusChange = (id) => {
    dispatch(paidStatusChangeThunk(id));
  };

  const changeStatus = (id) => {
    dispatch(freeStatusChangeThunk(id));
  };

  const modifyAttendance = (id) => {
    dispatch(modifyAttendanceThunk(id));
  };

  const createCert = (id) => {
    dispatch(createCertThunk(id));
  };

  const attendanceBulkAction = (ids) => {
    dispatch(attendanceBulk({ ids: ids }));
  };

  const CertsBulk = (ids) => {
    dispatch(createMultipleCerts({ ids: ids }));
  };

  const copyLinkMp = (id, data) => {
      let text = data.link
      var input = document.createElement('input');
      input.setAttribute('value', text);
      document.body.appendChild(input);
      input.select();
      var result = document.execCommand('copy');
      document.body.removeChild(input);
      return result;
  };

  const buttons = [
    {
      name: "Cancelar inscripción",
      overlay: "Cancelar inscripción",
      icon: "ban",
      conditional: { key: "status", opossiteValues: ["cancelled"] },
      className: "btnDanger",
      action: changeStatus,
      confirmMessage:
        "¿Está seguro de querer cancelar la inscripción a este evento?",
    },
    {
      name: "Aprobar inscripción",
      overlay: "Aprobar inscripción",
      icon: "check",
      action: paidStatusChange,
      conditional: { key: "status", opossiteValues: ["approved"] },
      className: "btnGreen",
      confirmMessage:
        "¿Está seguro de querer inscribir el usuario a este evento?",
    },
    {
      name: "Bonificar inscripción",
      overlay: "Bonificar inscripción",
      icon: "gift",
      conditional: { key: "status", opossiteValues: ["approved"] },
      action: changeStatus,
      confirmMessage:
        "¿Está seguro de querer inscribir el usuario a este evento?",
    },
    {
      name: "Usuario presente",
      overlay: "Agregar presente",
      icon: "user",
      conditional: { key: "attended", opossiteValues: [true] },
      className: "btnGreen",
      action: modifyAttendance,
      bulk: true,
      bulkAction: attendanceBulkAction,
      confirmMessage:
        "¿Está seguro de querer inscribir el usuario a este evento?",
    },
    {
      name: "Usuario ausente",
      overlay: "Quitar presente",
      icon: "user",
      conditional: { key: "attended", values: [true] },
      className: "btnDanger",
      action: modifyAttendance,
      confirmMessage:
        "¿Está seguro de querer inscribir el usuario a este evento?",
    },
    {
      name: "Generar certificado",
      overlay: "Generar certificado de asistencia",
      icon: "certificate",
      action: createCert,
      bulk: true,
      bulkAction: CertsBulk,
      confirmMessage: "¿Está seguro de querer crear el certificado?",
    },
    {
      name: "Copiar Link MP",
      overlay: "Copiar Link MP",
      icon: "copy",
      action: copyLinkMp,
      conditional: { key: "status", values: ["pending"] },
    }
  ];

  return (
    <>
      {loading ? (
        <p>Loading</p>
      ) : (
        <AdminTable
          bodyData={bodyData}
          tableHeader={tableHeader}
          buttons={buttons}
          buttonsWidth={"250px"}
          message={message}
          errMessage={errorMessage}
          size={size}
          multiSelect={true}
          headersXlsx={headersXlsx}
          headersXlsxAll={headersXlsxAll}
        />
      )}
    </>
  );
}
