import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createActivityThunk, getActivityThunk } from "../../redux/activities/thunks";
import CreateForm from "../../components/forms/CreateForm";
import renderInstructions from './activityData';

export default function EditActivity() {
  let { activityId } = useParams();
  const dispatch = useDispatch();
  const bodyData = useSelector((state) => state.activities.activity);
  const loading = useSelector((state) => state.activities.activityLoading);
  const error = useSelector(state => state.activities.activityError?.message);
  const message = useSelector(state => state.activities.activityMessage);
  let requestSent = false;
  const dataToRender = renderInstructions(bodyData);
  


  const formAction = (dataToSubmite) => {
    dispatch(createActivityThunk({data: dataToSubmite, activityId: activityId}));
  } 

  useEffect(() => {
      if(!requestSent) {
        dispatch(getActivityThunk(activityId));
    }
  }, []);


  return (
    <>
      {loading ? (
        <p>Loading</p>
      ) : (
        <Container >
          <h2 className="text-center">Editar actividad {bodyData.title} </h2>
          <Container style={{ marginTop: "30px" }}>
            <CreateForm formData={dataToRender} action={formAction} message={message} error={error}  />
          </Container>
        </Container>
      )}
    </>
  );
}
