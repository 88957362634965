import { FloatingLabel, Form, Col } from "react-bootstrap";

export default function TextInput({data, handleChange}) {

   let {label, value, type, size, name} = data;

    return (
        <Col xs={size}>
        <FloatingLabel
        controlId={`${name}-floatingInput`}
        label= {label}
        className="mb-3"
      >
        <Form.Control
        {...data.required && {required: true}}
          type= {type}
          name= {name}
          defaultValue={value}
          placeholder="name@example.com"
          onChange = {handleChange}
        />
        <Form.Control.Feedback />
        </FloatingLabel>
        </Col>
    )
  }
  