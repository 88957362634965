import { createAsyncThunk} from '@reduxjs/toolkit';
import { getPopup, updatePopup, deletePopUp } from '../../services/pop_upsService'

export const getPopupThunk = createAsyncThunk(
    'pop_ups/getBanner',
    async (data, {rejectWithValue}) => {
      try {
        const req = await getPopup();
        if (req.success !== true) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const updatePopupThunk = createAsyncThunk(
    'pop_ups/updateBanner',
    async (data, {rejectWithValue}) => {
      try {
        const req = await updatePopup(data);
        if (req.success !== true) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );
  
  export const deletePopUpThunk = createAsyncThunk(
    'pop_ups/getStatistics',
    async (data, {rejectWithValue}) => {
      try {
        const req = await deletePopUp();
        if (req.success !== true) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );
  


  