import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getStatisticsThunk } from "../../redux/homeView/thunks";

export default function GetBanner() {
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.homeView.loading);
    const statistics = useSelector((state) => state.homeView.statistics);
    const errorMessage = useSelector((state) => state.homeView.error?.message);

    useEffect(() => {
        dispatch(getStatisticsThunk());
    }, []);

    return (
        <>
            {loading ? (
                <p>Loading</p>
            ) : (
                <Container >
                    <h2 className="text-center">Datos generales</h2>
                    {
                        statistics
                            ?
                            <Container style={{ marginTop: "30px" }}>
                                <p>Cantidad de usuarios registrados: {statistics.usersCount}</p>
                                <p>Cantidad de actividades creadas: {statistics.activitiesCount}</p>
                                <p>Actividades activas: {statistics.activitiesActiveCount}</p>      
                            </Container>
                            :
                            null
                    }
                </Container>
            )}
        </>
    );
}
