import {Container, Nav, NavDropdown, Navbar} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {logOut} from '../../redux/auth/authSlice';
import { useEffect } from 'react';
export default function NavigationBar() {
  const user = useSelector((state) => state.auth.data);
  const dispatch = useDispatch();

  const logOutClick = () => {
    dispatch(logOut());
  }


    return (
      <>
<Navbar fixed="sticky" expand="lg" className= 'nav-background'>
  <Container fluid>
    <Navbar.Brand href="#home">
      <img
          alt=""
          src="/logo.svg"
          width="150px"
          className="d-inline-block align-top"
        />{' '}
    
     </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
      </Nav>
      <Nav>
      <Nav.Link>{user.name} {user.lastName}</Nav.Link>
      <Nav.Link onClick={logOutClick}>Cerrar sesión</Nav.Link>
      
    </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
  </>
    )

}


