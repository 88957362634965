import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useDispatch} from 'react-redux';
import {forgotThunk} from '../../redux/auth/thunks';
import styles from "./auth.module.css";
import { LinkContainer } from "react-router-bootstrap";
import React, { useState } from 'react';

export default function Forgot() {
    const [validated, setValidated] = useState(false);
    const [formData, updateFormData] = useState([]);
    const dispatch = useDispatch();
    const handleChange = (e) => {
      updateFormData({
        ...formData,
        [e.target.name]: e.target.value.trim()
      });
    };

    const handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else { 
        dispatch(forgotThunk(formData))
      }
      setValidated(true);
    };

  return (
    <Container fluid className={styles.palmBackground}>
      <Row className={styles.h100}>
        <Card className={`col-md-5 mx-auto my-auto ${styles.loginContainer}`}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Card.Body>
              <Card.Title className={styles.title}>Restablecer contraseña</Card.Title>
              <Card.Subtitle className={`mb-2 text-muted ${styles.subtitle}`}>
             
              </Card.Subtitle>
              <FloatingLabel
                controlId="floatingInput"
                label="Ingrese el email con el que se registró"
                className="mb-3 mt-4"
              >
                <Form.Control
                  required
                  type="email"
                  name="email"
                  placeholder="name@example.com"
                  onChange={handleChange}
                />
                <Form.Control.Feedback />
              </FloatingLabel>
              <Col className="text-center mt-4">
                <Button size="lg" type="submit" className={styles.button}>
                  Enviar
                </Button>
                <Col className="mt-3">
                  <LinkContainer to="/login">
                    <Card.Link>Acabo de recordar mi contraseña. Volver al login</Card.Link>
                  </LinkContainer>
                </Col>
              </Col>
            </Card.Body>
          </Form>
        </Card>
      </Row>
    </Container>
  );
}
