import { createSlice, isAnyOf, isPending, isRejected, isFulfilled, isRejectedWithValue  } from '@reduxjs/toolkit';
//sacamos los thunks de l archivos y los trabajamos en un archivo aparte
import { getBannerThunk, updateBannerThunk, getStatisticsThunk } from './thunks'
import {errorHandler} from '../utils/errorHandler'
const initialState = {
  banner: {},
  loading: true,
  error: null,
  eDescription: {},
  message: null,
  statistics: {}
};

export const homeViewSlice = createSlice({
  name: 'homeView',
  initialState,
  extraReducers: builder => {
    builder
    .addMatcher( 
      isPending(getBannerThunk, updateBannerThunk, getStatisticsThunk),
      (state) => {
        state.loading = true;
        state.error = null;
        state.errorDescription = {};
        state.message = null;
      }
    )
 
    .addMatcher(
      isFulfilled(getBannerThunk, updateBannerThunk, getStatisticsThunk),
      (state, action) => {
        state.loading = false;
        state.banner = action.payload.banner;
        state.message = action.payload.message;
        state.statistics = action.payload.statistics
      }
    )

   .addMatcher(
      isRejectedWithValue(getBannerThunk, updateBannerThunk, getStatisticsThunk),
      (state, action) => {
        errorHandler(action.payload)
        state.loading = false;
        state.eDescription = action.payload;
        state.error = {
          message: 'Ha ocurrido un error, por favor intente nuevamente en unos momentos',
        } 
      }
    )
  }
});



export default homeViewSlice.reducer;
