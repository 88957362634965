export default function dataToRender(bodyData) { 
    let formData = [
        {
          label: "titulo 1",
          value: bodyData?.title1,
          type: "text",
          size: 4,
          name: "title1",
          required: true,
        },
        {
          label: "titulo 2",
          value: bodyData?.title2,
          type: "text",
          size: 4,
          name: "title2",
          required: true,
        },
        {
          label: "titulo 3",
          value: bodyData?.title3,
          type: "text",
          size: 4,
          name: "title3",
          required: true,
        },
        {
          label: "Link",
          value: bodyData?.url,
          type: "text",
          size: 4,
          name: "url"
        },
        {
          label: "Descripcion",
          value: bodyData?.description,
          type: "text",
          size: 12,
          name: "description",
          required: true,
        },
        {
          label: "Imagen",
          value: bodyData?.imgPath,
          type: "file",
          size: 6,
          name: "imgPath",
          required: true,
        },
      ];

      return formData

}