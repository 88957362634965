import React, { useEffect } from "react";
import { Container} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getBannerThunk, updateBannerThunk } from "../../redux/homeView/thunks";
import CreateForm from "../../components/forms/CreateForm";
import renderInstructions from './bannerData';

export default function GetBanner() {
  const dispatch = useDispatch();
  const bodyData = useSelector((state) => state.homeView.banner);
  const loading = useSelector((state) => state.homeView.loading);
  const message = useSelector((state) => state.homeView.message);
  const errorMessage = useSelector((state) => state.homeView.error?.message);
  let requestSent = false;
  const dataToRender = renderInstructions(bodyData);



  const formAction = (dataToSubmite) => {
    dispatch(updateBannerThunk(dataToSubmite));
  } 

  useEffect(() => {
    
    if (!requestSent) {
      dispatch(getBannerThunk());
      requestSent = true;
    }
  }, []);

  

  return (
    <>
      {loading? (
        <p>Loading</p>
      ) : (
        <Container >
          <h2 className="text-center">Modificar Banner</h2>
          <Container style={{ marginTop: "30px" }}>
            <CreateForm formData={dataToRender} action={formAction} message={message} error={errorMessage} />
          </Container>
        </Container>
      )}
    </>
  );
}
