import { Navigate } from "react-router-dom";
import {useSelector} from 'react-redux';
import { Outlet } from 'react-router';

export default function NotLoggedIn() {
  let loggedIn= useSelector(state => state.auth.token);
    return (
      <>
      {loggedIn && <Navigate to="/" />}
      <Outlet />
      </>
    )
}


