import { createAsyncThunk} from '@reduxjs/toolkit';
import { re_subscribe, getAllUsers, getUser, updateUser, updateUserStatus, userDetails } from '../../services/usersServices';

export const getAllUsersThunk = createAsyncThunk(
    'users/getAllStatus',
    async (data, {rejectWithValue}) => {
      try {
        const req = await getAllUsers();
        if (req.success === false) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const getUserThunk = createAsyncThunk(
    'users/getUserStatus',
    async (data, {rejectWithValue}) => {
      try {
        const req = await getUser(data);
        if (req.success === false) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const updateUserThunk = createAsyncThunk(
    'users/updateUser',
    async (data, {rejectWithValue}) => {
      try {
        const req = await updateUser(data);
        if (req.success === false) { 
          return rejectWithValue(req)
        }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const updateUserStatusThunk = createAsyncThunk(
    'users/updateUserStatus',
    async (data, {rejectWithValue}) => {
      try {
        const req = await updateUserStatus(data);
        if (req.success === false) { 
          return rejectWithValue(req)
        }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const userDetailsThunk = createAsyncThunk(
    'users/getUserDetails',
    async (data, {rejectWithValue}) => {
      try {
        const req = await userDetails(data);
        if (req.success === false) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const re_subscribe_thunk = createAsyncThunk(
    'users/regenerate_ml_link',
    async (data, {rejectWithValue}) => {
      try {
        const subscribe = await re_subscribe(data);
        if (subscribe.success === false) { 
           return rejectWithValue(re_subscribe)
         }
        const user_details  = await userDetails(data);
        if (user_details.success === false) { 
           return rejectWithValue(user_details)
         }
        return user_details;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );


  

  


  