import { createAsyncThunk} from '@reduxjs/toolkit';
import { getAllActivities, createActivity, updateActivity, deleteActivity, getActivity, getSubscribedUsers } from '../../services/activitiesService'

export const getAllActivitiesThunk = createAsyncThunk(
    'activities/getAllStatus',
    async (data, {rejectWithValue}) => {
      try {
        const req = await getAllActivities();
        if (!req.success) { 
          return rejectWithValue(req)
        }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );


  export const createActivityThunk = createAsyncThunk(
    'activities/createActivity',
    async (data, {rejectWithValue}) => {
      try {
        let req
        if(data.activityId){
          req = await updateActivity(data.data, data.activityId);
        }else{
          req = await createActivity(data.data);
        }
        if (!req.success) { 
          return rejectWithValue(req)
        }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const getActivityThunk = createAsyncThunk(
    'activities/getActivity',
    async (id, {rejectWithValue}) => {
      try {
        const req = await getActivity(id);
        if (!req.success) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  
  export const deleteActivityThunk = createAsyncThunk(
    'activities/deleteActivityThunk',
    async (id, {rejectWithValue}) => {
      try {
        const req = await deleteActivity(id);
        if (req.success === false) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        return rejectWithValue(e)
      }
    }
  );

  export const getSubscribedUsersThunk = createAsyncThunk(
    'activities/getSubscribedUsers',
    async (id, {rejectWithValue}) => {
      try {
        const req = await getSubscribedUsers(id);
        if (!req.success) { 
           return rejectWithValue(req)
         }
        return req;
      } catch (e) {
        console.log(e)
        return rejectWithValue(e)
      }
    }
  );

  

  


  