import axios from "axios";
import { authHeader } from './authHeader'
//SIEMPRE TOMAR LA API_URL DE LOS AMBIENTES
import { api_url } from '../config'


export const getBanner = async () => {
  try {
    let options = {
      headers: {
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${api_url}/homeView/getBanner`,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

export const getStatistics = async () => {
  try {
    let options = {
      headers: {
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${api_url}/homeView/getStatistics`,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};


export const updateBanner = async (data) => {
  try {
   
    let options = {
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        // 'Content-Type': `multipart/form-data;`,
        ...authHeader()
      }
    };
    const response = await axios.post(
      `${api_url}/homeView/updateBanner`,
      data,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};