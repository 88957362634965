//UTILIZAR SIEMPRE AXIOS
import axios from "axios";
//SIEMPRE TOMAR LA API_URL DE LOS AMBIENTES
import { api_url } from '../config'
// axios.defaults.withCredentials = true

//EN LOS SERVICIOS NO MANEJAMOS LOGICA. SOLO LOS LLAMADOS DE API.
//NO USAR LA PALABRA SERVICE DESPUES DE LAS FUNCIONES - SIMPLEMENTE DEJAR EL NOMBRE DE LA FUNCION

export const register = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
      },
    };
    const response = await axios.post(
      `${api_url}/users/signup`,
      JSON.stringify(data),
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

export const login = async (data) => {
  try {
    let response = await axios.post(
      `${api_url}/login`,
      JSON.stringify(data),
      { headers: { "content-type": "application/json" } }
    );
    return response.data;
  } catch (e) {
    return e;
  }
};



export const forgot = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
      },
    };
    const response = await axios.post(
      `${api_url}/api/auth/forgot`,
      JSON.stringify(data),
      options
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const verifyResetToken = async (tokenReset) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
      },
    };
    const response = await axios.get(
      `${api_url}/api/auth/reset/${tokenReset}`,
      options
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const resetPassword = async (data) => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
      },
    };
    const response = await axios.post(
      `${api_url}/api/auth/reset`,
      JSON.stringify(data),
      options
    );
    return response;
  } catch (e) {
    return e;
  }
};

export const logout = () => {
  localStorage.removeItem("user");
  window.location.replace("/login");
};
