import React, { useEffect, useState } from 'react';
import { Container, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { getCodesByClientsAndActivityThunk } from '../../redux/codes/thunks';
import { useParams } from "react-router-dom";
import AdminTable from '../../components/tables/Table';

export default function CodesByClientActivity() {
    const { client } = useParams();
    const { activityId } = useParams();
    console.log(client, activityId)
    const dispatch = useDispatch();
    const bodyData = useSelector((state) => state.codes.allCodes);
    const error = useSelector((state) => state.codes.allCodesError);
    const loading = useSelector((state) => state.codes.allCodesLoading);
    const message = useSelector((state) => state.codes.allCodesMessage);
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        dispatch(getCodesByClientsAndActivityThunk({ activityId: activityId, id: client }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    const tableHeader = [
        { label: "Codigo", name: 'code' },
        { label: "Usado", name: "used", positive: 'Si', negative: 'No' },
        { label: "Usado por", name: 'used_by.lastName' },
    ];

    const headersXlsx = {
        _id: "Cliente",
        code: "Código"
      }

    return (
        <>
            {error ? (
                <p>Ha ocurrido un error. Por favor vuelva en intentar en unos minutos</p>
            ) : loading ? (
                <p>Loading</p>
            ) : (
                <Container fluid>
                    <h2 className="text-center">Todos los códigos de {bodyData ? bodyData[0].client : ''}</h2>
                    <Container fluid style={{ marginTop: "30px" }} >
                        <AdminTable
                            bodyData={bodyData}
                            headersXlsx={headersXlsx}
                            tableHeader={tableHeader}
                            buttonsWidth={'162px'}
                            size={20}
                        />
                    </Container>
                </Container>
            )}
        </>
    );
}
