export default function dataToRenderSubscription(bodyData) {
    let formData = [
        {
            label: "Fecha de último pago",
            value: bodyData?.subscription?.lastCharged?.substring(0, 10),
            type: "date",
            size: 4,
            name: "lastCharged"
        },
        {
            label: "Último monto cobrado",
            value: bodyData?.subscription?.chargedAmount,
            type: "text",
            size: 4,
            name: "chargedAmount"
        },
        {
        label: "Emitir factura al cargar monto",
        value: false,
        type: "switch",
        size: 4,
        name: "invoice",
        inputOptions: [{value: true}]
      }]

    return formData

}