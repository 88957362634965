import React, { useEffect } from "react";
import { Container, Button} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPopupThunk, updatePopupThunk, deletePopUpThunk } from "../../redux/pop_ups/thunks";
import CreateForm from "../../components/forms/CreateForm";
import renderInstructions from './pop_up_data';

export default function GetPopUp() {
  const dispatch = useDispatch();
  const bodyData = useSelector((state) => state.pop_ups);
  const loading = useSelector((state) => state.pop_ups.loading);
  const message = useSelector((state) => state.pop_ups.message);
  const errorMessage = useSelector((state) => state.pop_ups.error?.message);
  let requestSent = false;
  const dataToRender = renderInstructions(bodyData);


  const formAction = (dataToSubmite) => {
    dispatch(updatePopupThunk(dataToSubmite));
  } 


  useEffect(() => {
    
    if (!requestSent) {
      dispatch(getPopupThunk());
      requestSent = true;
    }
  }, []);

  

  return (
    <>
      {loading ? (
        <p>Loading</p>
      ) : (
        <Container>
          <h2 className="text-center">Crear o editar Popup</h2>
          <Container style={{ marginTop: "30px" }}>
            <CreateForm
              formData={dataToRender}
              action={formAction}
              message={message}
              error={errorMessage}
            />
          </Container>
        </Container>
      )}
    </>
  );
}
