import axios from "axios";
import { authHeader } from './authHeader'
//SIEMPRE TOMAR LA API_URL DE LOS AMBIENTES
import { api_url } from '../config'


export const getSubscription = async () => {
  try {
    let options = {
      headers: {
        "content-type": "application/json",
        ...authHeader()
      },
    };
    const response = await axios.get(
      `${api_url}/subscription`,
      options
    );
    
    return response.data;
  } catch (e) {
    return e;
  }
};


export const updateSubscription= async (data) => {
  try {
    let options = {
      headers: {
        'content-Type': "application/json",
        ...authHeader()
      },
    };
    const response = await axios.put(
      `${api_url}/subscription`,
      data,
      options
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

